<template>
    <v-navigation-drawer class="navigation" ref="drawer" app right hide-overlay :width="navigation.width" :class="{ 'sidebar-show' : showAnimation, 'sidebar-hidden' : hiddenAnimation }">
        <div class="container--fluid" v-if="flow">
            <v-card ref="vCardTitle">
                <v-card-title class="p-2">
                    {{ $t("dashboard_support.support_flow.edit.title") }}
                </v-card-title>
            </v-card>
            <template class="container-fluid">

                <!-- ヘッダ情報 start -->
                <div class="d-flex justify-content-between mt-2">
                    <div class="d-flex justify-content-between gap-5 align-content-center" ref="infoSupportFlow">
                        <span>
                            支援満了ポイント: {{flow.requried_support_point}}pt
                        </span>
                        <div>
                            <p class="mb-0">支援計画合計ポイント(プロセス評価): {{sumOfPointSupportA + sumOfPointSupportB + sumOfPointSupport}}pt</p>
                            <div class="ml-3">
                                <p class="mb-0" v-if="numOfPointSupportA > 0">支援A: {{numOfPointSupportA}}回 : {{sumOfPointSupportA}}pt</p>
                                <p class="mb-0" v-if="numOfPointSupportB > 0">支援B: {{numOfPointSupportB}}回 : {{sumOfPointSupportB}}pt</p>
                                <p class="mb-0" v-if="numOfPointSupport > 0">支援: {{numOfPointSupport}}回 : {{sumOfPointSupport}}pt</p>
                            </div>
                        </div>
                    </div>
                    <div v-if="flow">
                        <button v-if="can_recreate_flow" type="button" class="btn btn-danger" @click="confirmRecreateFlow">
                            {{ $t("dashboard_support.support_flow.edit.create_flow") }}
                        </button>
                    </div>
                </div>
                <!-- ヘッダ情報 end -->
                <hr/>
                <div class="container-form-support mt-2 mb-2 d-flex flex-column gap-3">
                    <!-- セグメントループ start -->
                    <div class="border-black pb-3" v-for="(segment, index) in flow.health_support_flow_segments" :key="index" 
                        :class="['segment-block-edit', 'fade-' + index, segment.is_new ? null : processTagColor(segment, flow.support_flow_status, flow.health_support_flow_segments[index-1])]">
                        
                        <!-- 既存セグメントの場合 start -->
                        <div v-if="!segment?.is_new" :class="{'block-confirmed': parseInt(segment?.support_progress_status) === SUPPORT_FLOW_STATUS_CONFIRM}">
                            <div class="border-black-bottom container-title d-flex align-items-center justify-content-between" :class="classSegment(flow.health_support_flow_segments[index])">
                                <div class="pl-4">
                                    <span v-if="checkSupportFirst(segment)">
                                        <span v-if="codeStatusSupport"> {{codeStatusSupport[parseInt(segment?.health_support_form?.support_form_div)]}}</span>
                                        <span v-if="checkSplitFirstSupport(segment)">&nbsp;(分割実施)</span>
                                    </span>
                                    <span v-else-if="checkSplitFirstSupportSecond(segment)">
                                        <span v-if="codeStatusSupport"> {{codeStatusSupport[codeStatusSupportFirst]}}</span>
                                        <span v-if="checkSplitFirstSupport(segment)">&nbsp;(分割実施)</span>
                                    </span>
                                    <span v-else-if="checkSupportFinal(segment)">
                                        <span v-if="codeStatusSupport"> {{codeStatusSupport[parseInt(segment?.health_support_form?.support_form_div)]}}</span>
                                    </span>
                                    <span v-else>
                                        {{segment?.support_flow_seq}}. {{codeStatusSupport[parseInt(segment?.health_support_form?.support_form_div)]}}
                                    </span>
                                </div>
                                <!-- ドロップダウンメニュー(後続支援追加、削除) -->
                                <div class="border-left pr-2" v-if="canEditFlow() && !checkSupportFinal(segment) && !checkSplitFirstSupportFirst(segment)">
                                    <div class="position-relative position-relative-left">
                                        <span class="nav-link dropdown-toggle" id="select" data-bs-placement="top" data-bs-toggle="dropdown" aria-expanded="false"></span>
                                        <ul class="dropdown-menu" aria-labelledby="select">
                                            <li class="dropdown-item" v-if="checkCanCreateSegmentAfter(segment, index)" @click="addSegment(index)">後続支援追加</li>
                                            <li v-if="!checkSupportFirst(segment) && !checkSupportFinal(segment) && checkCanDelete(segment)" class="dropdown-item" @click="deleteSegment(index)">削除</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <!-- コンテンツ start -->
                            <div class="row">
                                <!-- 上下移動ボタン (継続支援かつ編集可能な場合) start -->
                                <div class="col-auto pl-8 d-flex flex-column justify-content-center align-items-center pb-0 left-end-width" v-if="checkContinueSupport(segment) && canEditContinuousSupportSegment(segment)">
                                    <p><i class="fas fa-arrow-circle-up fa-2x text-success pointer_cursor" @click="upSegment(index)"></i></p>
                                    <p><i class="fas fa-arrow-circle-down fa-2x text-success pointer_cursor" @click="downSegment(index)"></i></p>
                                </div>
                                <div class="col-auto pb-0 left-end-width" v-else></div>
                                <!-- 上下移動ボタン end -->

                                <!-- 現在支援状況 start -->
                                <div class="col-lg-4 col-md-12 pt-0">
                                    <div>
                                        <span>
                                            現在支援状況
                                        </span>
                                    </div>
                                    <!-- 現在支援状況 ボディ start -->
                                    <div class="body p-2 border-black">
                                        <div class="row">
                                            <div class="col-6 pt-0 pb-1"><span class="border-bottom-style">予定</span></div>
                                            <div class="col-6 pt-0 pb-1"><span class="border-bottom-style">実施</span></div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6 pt-1">
                                                <div class="row">
                                                    <div class="col-3 pt-0 pb-0 text-no-wrap">依頼者:</div>
                                                    <div class="col-9 pt-0 pb-0 overflow-hidden ellipsis">
                                                        {{supportAssignorUser(segment)}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6 pt-1"></div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6 pt-1">
                                                <div class="row">
                                                    <div class="col-3 pt-0 pb-0 text-no-wrap">担当者:</div>
                                                    <div class="col-9 pt-0 pb-0 overflow-hidden ellipsis">
                                                        {{scheduleSupportStaffUser(segment)}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6 pt-1">
                                                <div class="row">
                                                    <div class="col-3 pt-0 pb-0 text-no-wrap">担当者:</div>
                                                    <div class="col-9 pt-0 pb-0 overflow-hidden ellipsis">
                                                        {{supportStaffUser(segment)}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6 pt-1">
                                                <div class="row">
                                                    <div class="col-3 pt-0 pb-0 text-no-wrap">
                                                        <template v-if="segment?.support_stop_flag === 0
                                                            && [PLAN].indexOf(parseInt(segment?.support_progress_status)) >= 0
                                                            && segment?.scheduled_support_datetime !== ''">
                                                            <span class="text-schedule-date">計画日:</span>
                                                        </template>
                                                        <template v-if="segment?.support_stop_flag === 0
                                                            && [SCHEDULE, IN_PROGRESS, CONFIRM].indexOf(parseInt(segment?.support_progress_status)) >= 0
                                                            && segment?.plan_support_datetime !== ''">
                                                            予定日:
                                                        </template>
                                                    </div>
                                                    <div class="col-9 pt-0 pb-0 overflow-hidden ellipsis">
                                                        <template v-if="segment?.support_stop_flag === 0
                                                            && [PLAN].indexOf(parseInt(segment?.support_progress_status)) >= 0
                                                            && segment?.scheduled_support_datetime != ''">
                                                            <span class="text-schedule-date">{{planSupportTimeDisp(segment)}}</span>
                                                        </template>
                                                        <template v-if="segment?.support_stop_flag === 0
                                                            && [SCHEDULE, IN_PROGRESS, CONFIRM].indexOf(parseInt(segment?.support_progress_status)) >= 0
                                                            && segment?.plan_support_datetime != ''">
                                                            {{scheduleSupportTimeDisp(segment)}}
                                                        </template>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6 pt-1">
                                                <div class="row">
                                                    <div class="col-3 pt-0 pb-0 text-no-wrap">実施日:</div>
                                                    <div class="col-9 pt-0 pb-0 overflow-hidden ellipsis">
                                                        {{ segment?.support_date ? (segment?.support_date).substr(-5).replace('-', '/') : "" || '' }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6 pt-1">
                                                <div class="row" v-if="!checkSupportFinal(segment)">
                                                    <div class="col-3 pt-0 pb-0">形態:</div>
                                                    <div class="col-9 pt-0 pb-0 overflow-hidden ellipsis">
                                                        <!-- 初回支援or最終評価 -->
                                                        <template v-if="checkSupportFirst(segment) || checkSupportFinal(segment)">
                                                            {{ segment?.health_support_form?.support_form_name_disp_02 }}
                                                        </template>
                                                        <!-- 継続支援 -->
                                                        <template v-else-if="checkSupportA(segment) || checkSupportB(segment) || checkSupport(segment) || checkMidEval(segment)">
                                                            <span v-if="segment?.health_support_form?.support_form_name_disp_01 || segment?.support_amount">
                                                                {{ segment?.health_support_form?.support_form_name_disp_01 }}({{ segment?.scheduled_support_amount_org }}{{ segment?.health_support_form?.support_amount_unit }})
                                                            </span>
                                                            <span v-else>
                                                                -
                                                            </span>
                                                        </template>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6 pt-1 mb-2">
                                                <div class="row">
                                                    <div class="col-3 pt-0 pb-0">形態:</div>
                                                    <!-- 初回支援or最終評価 -->
                                                    <div class="col-9 ml-0 pt-0 pb-0 overflow-hidden ellipsis" v-if="checkSupportFirst(segment) || checkSupportFinal(segment)">
                                                        {{ segment?.health_support_form?.support_form_name_disp_02 }}
                                                    </div>
                                                    <!-- 継続支援 -->
                                                    <div class="col-9 pt-0 pb-0 text-nowrap overflow-hidden ellipsis" v-else-if="checkSupportA(segment) || checkSupportB(segment) || checkSupport(segment) || checkMidEval(segment)">
                                                    <span v-if="segment?.support_stop_flag == false && SUPPORT_FLOW_STATUS_CONFIRM == segment?.support_progress_status && (segment?.health_support_form?.support_form_name_disp_01 || segment?.support_amount)" >
                                                        {{ segment?.health_support_form?.support_form_name_disp_01 }}({{ segment?.support_amount }}{{ segment?.health_support_form?.support_amount_unit }})
                                                    </span>
                                                    <span v-else>
                                                        -
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- 初回支援の場合 -->
                                        <div class="row" v-if="checkSupportFirst(segment)">
                                            <!-- 場所 -->
                                            <div class="col-6 pt-1 mb-2">
                                                <div class="row">
                                                    <div class="col-3 pt-0 pb-0 text-no-wrap">場所:</div>
                                                    <div class="col-9 pt-0 pb-0 overflow-hidden ellipsis">{{segment?.scheduled_support_location?.item_name || '-'}}</div>
                                                </div>
                                            </div>
                                            <div class="col-6 pt-1"></div>
                                        </div>
                                        <!-- 継続支援の場合 -->
                                        <div class="row" v-if="!checkSupportFirst(segment) && !checkSupportFinal(segment)">
                                            <!-- ポイント(予定) -->
                                            <div class="col-6 pt-1">
                                                <div class="row">
                                                    <div class="col-3 pt-0 pb-0 text-no-wrap">{{ $t('dashboard_support.support_flow.point') }}:</div>
                                                    <div class="col-9 pt-0 pb-0 overflow-hidden ellipsis">
                                                        {{segment?.scheduled_support_point_org || '-'}}
                                                    </div>
                                                </div>
                                            </div>
                                             <!-- ポイント(実施) -->
                                            <div class="col-6 pt-1">
                                                <div class="row">
                                                    <div class="col-3 pt-0 pb-0 text-no-wrap">{{ $t('dashboard_support.support_flow.point') }}:</div>
                                                    <div class="col-9 pt-0 overflow-hidden ellipsis">
                                                        {{segment?.support_point || '-'}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- 現在支援状況 ボディ end -->
                                </div>
                                <!-- 現在支援状況 end -->

                                <!-- 支援形態 start -->
                                <div class="col-lg-7 col-md-12 pr-6 pb-0 pt-0">
                                    <!-- 初回支援 支援形態 -->
                                    <template v-if="checkSupportFirst(segment) && !checkSplitFirstSupport(segment)">
                                        <!-- 支援中止フラグがOFF、かつ進捗が「計画」or「予定」or「実施中」 -->
                                        <div class="row d-flex align-items-center">
                                            <!-- 支援形態 -->
                                            <div class="col-2" :class="classSegment(segment)">
                                                初回面接形態
                                            </div>
                                            <div class="col-4 pl-0">
                                                <select :disabled="!canEditFirstSupportSegment(segment)" name="" class="form-select" :key="index" v-model="segment.health_support_form_id">
                                                    <option v-for="item in firstSupportFormList" :value="item.id" :key="item.id">{{item.support_form_name_disp_01}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <!-- 支援形態情報 -->
                                            <div class="col-2">最低介入量</div>
                                            <div class="col-4">
                                                <p class="text-no-wrap">
                                                    {{showFirstSupportForm(segment)?.min_support_amount}}{{showFirstSupportForm(segment)?.support_amount_unit}}
                                                </p>
                                            </div>
                                        </div>
                                    </template>

                                    <!-- 初回支援 (分割実施) -->
                                    <template v-if="checkSplitFirstSupport(segment)">
                                        <div class="row d-flex align-items-center">
                                            <!-- 支援形態 -->
                                            <div class="col-2 pr-0" :class="classSegment(segment)">初回面接形態</div>
                                            <div class="col-3 pl-0">
                                                <!-- 支援4期 -->
                                                <select @change="onContinuousSupportFormChanged(segment, continueSupportForm)" :disabled="!canEditFirstSupportSegment(segment)" name="" class="form-select" :key="index" v-model="segment.health_support_form_id">
                                                    <option v-for="item in (checkSplitFirstSupportSecond(segment) ? firstSplitSupportFormList : firstSupportFormList)" :value="item.id" :key="item.id">{{item.support_form_name_disp_01}}</option>
                                                </select>
                                            </div>
                                            <!-- 実施量 -->
                                            <div class="col-2 pr-0" :class="classSegment(segment)">
                                                <!-- 実施量 ラベル -->
                                                実施({{findSupportForm(segment, (checkSplitFirstSupportSecond(segment) ? firstSplitSupportFormList : firstSupportFormList))?.support_amount_unit}})
                                            </div>
                                            <div class="col-2 pl-0">
                                                <!-- 実施量 入力 -->
                                                <input type="number" class="form-control" v-model="segment.scheduled_support_amount"
                                                    :disabled="!canEditContinuousSupportSegment(segment) || (checkSplitFirstSupportSecond(segment) ? firstSplitSupportFormList : firstSupportFormList)?.support_amount_fix_flg == 1"
                                                    :step="1"
                                                    :min="(checkSplitFirstSupportSecond(segment) ? firstSplitSupportFormList : firstSupportFormList)?.min_support_amount"
                                                    :max="9999"
                                                    @change="updatePoint(index, $event)">
                                            </div>
                                        </div>
                                    </template>

                                    <!-- 継続支援 支援形態 -->
                                    <template v-if="checkContinueSupport(segment)">
                                        <div class="row d-flex align-items-center">
                                            <!-- 予定時期 -->
                                            <div class="col-1 pr-0" :class="classSegment(segment)">予定時期</div>
                                            <div class="col-3 pl-0">
                                                <select :disabled="!canEditContinuousSupportSegment(segment)" name="" class="form-select" :key="index" v-model="segment.scheduled_support_term">
                                                    <option v-for="(text, key) in intendDateList" :value="key" :key="key">{{text}}</option>
                                                </select>
                                            </div>
                                            <!-- 支援形態 -->
                                            <div class="col-1 pr-0" :class="classSegment(segment)">支援形態</div>
                                            <div class="col-3 pl-0">
                                                <!-- 支援A -->
                                                <select v-if="checkSupportA(segment)" @change="onContinuousSupportFormChanged(segment, continueSupportFormA)" :disabled="!canEditContinuousSupportSegment(segment)" name="" class="form-select" :key="index" v-model="segment.health_support_form_id">
                                                    <option v-for="item in continueSupportFormA" :value="item.id" :key="item.id">{{item.support_form_name_disp_01}}</option>
                                                </select>
                                                <!-- 支援B -->
                                                <select v-if="checkSupportB(segment)" @change="onContinuousSupportFormChanged(segment, continueSupportFormB)" :disabled="!canEditContinuousSupportSegment(segment)" name="" class="form-select" :key="index" v-model="segment.health_support_form_id">
                                                    <option v-for="item in continueSupportFormB" :value="item.id" :key="item.id">{{item.support_form_name_disp_01}}</option>
                                                </select>
                                                <!-- 支援4期 -->
                                                <select v-if="checkSupport(segment)" @change="onContinuousSupportFormChanged(segment, continueSupportForm)" :disabled="!canEditContinuousSupportSegment(segment)" name="" class="form-select" :key="index" v-model="segment.health_support_form_id">
                                                    <option v-for="item in continueSupportForm" :value="item.id" :key="item.id">{{item.support_form_name_disp_01}}</option>
                                                </select>
                                            </div>
                                            <!-- 実施量 -->
                                            <div class="col-2 pr-0" :class="classSegment(segment)">
                                                <!-- 実施量 ラベル -->
                                                実施({{showSupportForm(segment, continueSupportFormA, continueSupportFormB, continueSupportForm)?.support_amount_unit}})
                                            </div>
                                            <div class="col-2 pl-0">
                                                <!-- 実施量 入力 -->
                                                <input type="number" class="form-control" v-model="segment.scheduled_support_amount"
                                                    :disabled="!canEditContinuousSupportSegment(segment) || showSupportForm(segment, continueSupportFormA, continueSupportFormB, continueSupportForm)?.support_amount_fix_flg == 1"
                                                    :step="1"
                                                    :min="showSupportForm(segment, continueSupportFormA, continueSupportFormB, continueSupportForm)?.min_support_amount" 
                                                    :max="9999"
                                                    @change="updatePoint(index, $event)">
                                            </div>
                                        </div>
                                        <div class="row d-flex align-items-center">
                                            <!-- 中間評価フラグ -->
                                            <div class="col-2 pr-0"><label :for="`mid_eval_flg${index}`">中間評価</label></div>
                                            <div class="col-2 pl-0">
                                                <input type="checkbox"
                                                    :disabled="!canEditContinuousSupportSegment(segment)"
                                                    v-model="segment.mid_eval_flg" 
                                                    :id="`mid_eval_flg${index}`"
                                                    >
                                            </div>
                                             <!-- 支援形態情報 -->
                                            <div class="col-4 pl-0">
                                                <p class="text-no-wrap">最低介入量: {{showSupportForm(segment, continueSupportFormA, continueSupportFormB, continueSupportForm)?.min_support_amount}}{{showSupportForm(segment, continueSupportFormA, continueSupportFormB, continueSupportForm)?.support_amount_unit}}</p>
                                                <p class="text-no-wrap">基準ポイント: {{showSupportForm(segment, continueSupportFormA, continueSupportFormB, continueSupportForm)?.basis_point}}pt</p>
                                                <!-- <p class="text-no-wrap">最大有効ポイント: {{showSupportForm(segment, continueSupportFormA, continueSupportFormB, continueSupportForm)?.max_point}}pt</p> -->
                                            </div>
                                            <!-- 実施ポイント -->
                                            <div class="col-2 pr-0" :class="classSegment(segment)">プロセス評価ポイント</div>
                                            <div class="col-2 pl-0">
                                                <input type="number" class="form-control"
                                                    :disabled="!canEditContinuousSupportSegment(segment)"
                                                    :step="1" 
                                                    :min="0" 
                                                    :max="9999" 
                                                    :key="index" 
                                                    v-model="segment.scheduled_support_point"
                                                    @change="calculate()">
                                            </div>
                                        </div>
                                    </template>

                                     <!-- 最終評価 支援形態 -->
                                    <template v-if="checkSupportFinal(segment)">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-2 pr-0" :class="classSegment(segment)">
                                                予定時期
                                            </div>
                                            <div class="col-3 pl-0">
                                                <select name="" class="form-select" :disabled="!canEditLastSupportSegment(segment)" :key="index" v-model="segment.scheduled_support_term">
                                                    <option v-for="(text, key) in intendDateList" :value="key" :key="key">{{text}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                                <!-- 支援形態 end -->
                            </div>
                            <!-- コンテンツ end -->
                        </div>
                        <!-- 既存セグメントの場合 end -->

                        <!-- 新規セグメントの場合 start -->
                        <div v-else>
                            <!-- タイトル部 -->
                            <div class="border-black-bottom container-title d-flex align-items-center justify-content-between bg-continue-support">
                                <!-- 支援A or 支援B 選択コンボボックス -->
                                <select v-model="segment.health_support_form.support_form_div" class="form-select ml-3" style="width: 150px" :key="segment.id" @change="changeTypeSupport(index, $event)">
                                    <option :value="codeStatusSupportA">支援A (第3期)</option>
                                    <option :value="codeStatusSupportB">支援B (第3期)</option>
                                    <option :value="codeStatusSupportContinuing">支援 (第4期)</option>
                                </select>
                                <!-- ドロップダウンメニュー(後続支援追加、削除) -->
                                <div class="border-left p-2">
                                    <div class="position-relative position-relative-left">
                                        <span class="nav-link dropdown-toggle" data-bs-placement="top" data-bs-toggle="dropdown" aria-expanded="false"></span>
                                        <ul class="dropdown-menu" aria-labelledby="select">
                                            <li class="dropdown-item" @click="addSegment(index)" >後続支援追加</li>
                                            <li class="dropdown-item" @click="deleteSegment(index)">削除</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <!-- コンテンツ部 -->
                            <div class="row">
                                <!-- 上下移動ボタン (継続支援かつ編集可能な場合) start -->
                                <div class="col-auto pl-8 d-flex flex-column justify-content-center align-items-center pb-0 left-end-width">
                                    <p><i class="fas fa-arrow-circle-up fa-2x text-success pointer_cursor" @click="upSegment(index)"></i></p>
                                    <p><i class="fas fa-arrow-circle-down fa-2x text-success pointer_cursor" @click="downSegment(index)"></i></p>
                                </div>
                                 <!-- 上下移動ボタン end -->

                                 <!-- 現在支援状況 start -->
                                <div class="col-lg-4 col-md-12"></div>
                                <!-- 現在支援状況 end -->

                                <!-- 継続支援 支援形態 start -->
                                <div class="col-lg-7 col-md-12 pr-6">
                                    <template>
                                        <div class="row d-flex align-items-center">
                                            <!-- 予定時期 -->
                                            <div class="col-1 pr-0" :class="classSegment(segment)">予定時期</div>
                                            <div class="col-3 pl-0">
                                                <select name="" class="form-select" :key="index" v-model="segment.scheduled_support_term">
                                                    <option v-for="(text, key) in intendDateList" :value="key" :key="key">{{text}}</option>
                                                </select>
                                            </div>
                                            <!-- 支援形態 -->
                                            <div class="col-1 pr-0" :class="classSegment(segment)">支援形態</div>
                                            <div class="col-3 pl-0">
                                                <!-- 支援A -->
                                                <select v-if="checkSupportA(segment)" @change="onContinuousSupportFormChanged(segment, continueSupportFormA)"  name="" class="form-select" :key="index" v-model="segment.health_support_form_id">
                                                    <option v-for="item in continueSupportFormA" :value="item.id" :key="item.id">{{item.support_form_name_disp_01}}</option>
                                                </select>
                                                <!-- 支援B -->
                                                <select v-if="checkSupportB(segment)" @change="onContinuousSupportFormChanged(segment, continueSupportFormB)" name="" class="form-select"  :key="index" v-model="segment.health_support_form_id">
                                                    <option v-for="item in continueSupportFormB" :value="item.id" :key="item.id">{{item.support_form_name_disp_01}}</option>
                                                </select>
                                                <!-- 支援4期 -->
                                                <select v-if="checkSupport(segment)" @change="onContinuousSupportFormChanged(segment, continueSupportForm)" name="" class="form-select"  :key="index" v-model="segment.health_support_form_id">
                                                    <option v-for="item in continueSupportForm" :value="item.id" :key="item.id">{{item.support_form_name_disp_01}}</option>
                                                </select>
                                            </div>
                                            <!-- 実施量 -->
                                            <div class="col-2 pr-0" :class="classSegment(segment)">
                                                <!-- 実施量 ラベル -->
                                                実施({{showSupportForm(segment, continueSupportFormA, continueSupportFormB, continueSupportForm)?.support_amount_unit}})
                                            </div>
                                            <div class="col-2 pl-0">
                                                 <!-- 実施量 入力 -->
                                                <input type="number" class="form-control" v-model="segment.scheduled_support_amount" 
                                                    :disabled="showSupportForm(segment, continueSupportFormA, continueSupportFormB, continueSupportForm)?.support_amount_fix_flg == 1"
                                                    :step="1"
                                                    :min="showSupportForm(segment, continueSupportFormA, continueSupportFormB, continueSupportForm)?.min_support_amount"
                                                    :max="9999"
                                                    @change="updatePoint(index, $event)">
                                            </div>
                                        </div>
                                        <div class="row d-flex align-items-center">
                                            <!-- 中間評価フラグ -->
                                            <div class="col-2 pr-0"><label :for="`mid_eval_flg${index}`">中間評価</label></div>
                                            <div class="col-2 pl-0">
                                                <input type="checkbox" v-model="segment.mid_eval_flg" :id="`mid_eval_flg${index}`">
                                            </div>
                                            <!-- 支援形態情報 -->
                                            <div class="col-4 pl-0">
                                                <p class="text-no-wrap">最低介入量: {{showSupportForm(segment, continueSupportFormA, continueSupportFormB, continueSupportForm)?.min_support_amount}}{{showSupportForm(segment, continueSupportFormA, continueSupportFormB, continueSupportForm)?.support_amount_unit}}</p>
                                                <p class="text-no-wrap">基準ポイント: {{showSupportForm(segment, continueSupportFormA, continueSupportFormB, continueSupportForm)?.basis_point}}pt</p>
                                                <!-- <p class="text-no-wrap">最大有効ポイント: {{showSupportForm(segment, continueSupportFormA, continueSupportFormB, continueSupportForm)?.max_point}}pt</p> -->
                                            </div>
                                            <!-- 実施ポイント -->
                                            <div class="col-2 pr-0" :class="classSegment(segment)">プロセス評価ポイント</div>
                                            <div class="col-2 pl-0">
                                                <input type="number" class="form-control"
                                                    :step="1"
                                                    :min="0"
                                                    :max="9999"
                                                    :key="index"
                                                    v-model="segment.scheduled_support_point"
                                                    @change="calculate()">
                                            </div>
                                        </div>
                                    </template>
                                </div>
                                <!-- 継続支援 支援形態 end -->
                            </div>
                        </div>
                        <!-- 新規セグメントの場合 end -->
                    </div>
                    <!-- セグメントループ end -->
                </div>
            </template>

            <!-- フッタボタン群 -->
            <v-card ref="vCardAction">
                <v-card-actions class="d-flex align-content-center justify-content-between">
                    <button type="button" class="btn bg-gray" @click="closeSlide()">{{ $t("common.cancel") }}</button>
                    <button v-if="canEditFlow()" type="button" class="btn btn-primary" @click="updateSupportFlowConfirm">{{ $t("common.keep") }}</button>
                </v-card-actions>
            </v-card>

            <Dialog :maxWidth="600" :isShow="isShowDialogDelete" persistent>
                <v-card>
                    <v-container>
                        <v-card-title>
                            確認
                        </v-card-title>
                        <div class="m-3">
                            タスク割当済みです。削除してもよろしいですか？
                        </div>
                        <v-card-actions
                            class="
                                  d-flex
                                  align-content-center
                                  justify-content-between
                                  mr-2
                                  ml-2
                            "
                        >
                            <button
                                type="button"
                                class="btn btn-secondary"
                                @click="isShowDialogDelete = false">
                                キャンセル
                            </button>
                            <button
                                type="button"
                                class="btn btn-primary"
                                @click="deleteSegment(diaglogDeleteIndex, true)">
                                OK
                            </button>
                        </v-card-actions>
                    </v-container>
                </v-card>
            </Dialog>
            <Dialog :maxWidth="600" :isShow="isShowRecreateFlow" persistent>
                <v-card>
                    <v-container>
                        <v-card-title>
                            確認
                        </v-card-title>
                        <div class="m-3">
                            支援フローが再作成により、継続支援及び最終評価の支援内容が削除されます。<br>
                            再作成処理を実行します。よろしいですか？
                        </div>
                        <v-card-actions
                            class="
                                  d-flex
                                  align-content-center
                                  justify-content-between
                                  mr-2
                                  ml-2
                            "
                        >
                            <button
                                type="button"
                                class="btn btn-secondary"
                                @click="isShowRecreateFlow = false">
                                キャンセル
                            </button>
                            <button
                                type="button"
                                class="btn btn-primary"
                                @click="recreateFlowFunc()">
                                OK
                            </button>
                        </v-card-actions>
                    </v-container>
                </v-card>
            </Dialog>
            <Dialog :maxWidth="600" :isShow="isShowConfirm" persistent>
                <v-card>
                    <v-container>
                        <v-card-title>
                            保存
                        </v-card-title>
                        <div class="m-3">
                            保存処理を実行します。よろしいですか？
                        </div>
                        <v-card-actions
                            class="
                                  d-flex
                                  align-content-center
                                  justify-content-between
                                  mr-2
                                  ml-2
                            "
                        >
                            <button
                                type="button"
                                class="btn btn-secondary"
                                @click="isShowConfirm = false">
                                キャンセル
                            </button>
                            <button
                                type="button"
                                class="btn btn-primary"
                                @click="updateSupportFlow()">
                                OK
                            </button>
                        </v-card-actions>
                    </v-container>
                </v-card>
            </Dialog>
        </div>
    </v-navigation-drawer>
</template>

<script>
import { mapMutations } from "vuex";
import { DASHBOARD } from "@/constants"
import { RepositoryFactory } from "@/services/api/RepositoryFactory";
import mixins from "@/mixins"
import Repository from "../../../services/api/Repository";
import {CODE_STATUS_SUPPORT} from "@/common/constants";
import Dialog from "@/components/common/Dialog";
const DashboardRepository = RepositoryFactory.get(DASHBOARD)

export default {
    name: "EditFlowSupport",
    data() {
        return {
            BEFORE_START_SUPPORT: 10,
            SUPPORT_IN_PROGRESS: 20,
            SUPPORT_COMPLETE: 30,
            END_HALFWAY: 40,
            FIRST_INTERVIEW_DECLINE: 50,

            isOpenDropdown: false,
            isShowConfirm: false,
            isShowRecreateFlow: false,
            listSupport: [],
            activeFlow: {},
            CODE_FLOW_SUPPORTING: 20,
            numOfPointSupport: 0,
            numOfPointSupportA: 0,
            numOfPointSupportB: 0,
            sumOfPointSupport: 0,
            sumOfPointSupportA: 0,
            sumOfPointSupportB: 0,
            CODE_BEFORE_START: 10,
            CODE_IN_PROGRESS: 20,
            CODE_CONTINUE_SUPPORT: 20,
            CODE_FINAL_EVAL: 30,
            CODE_PLAN: 10,
            CODE_INTEND: 20,
            CODE_DOING: 30,
            CODE_SUPPORT_A: 20, // 支援A (3期)
            CODE_SUPPORT_B: 30, // 支援B (3期)
            CODE_SUPPORT: 60, // 支援 (4期)
            can_recreate_flow: null,
            codeStatusSupport: null,
            masterFormSupportList: [],
            intendDateList: [],
            continueSupportFormA: [],
            continueSupportFormB: [],
            continueSupportForm: [],
            isShowDialogDelete: false,
            diaglogDeleteIndex: false,
            listDeleteSegmentID: [],
            flow: false,
            // Support flow segment status
            SUPPORT_FLOW_STATUS_PLAN: 10,
            SUPPORT_FLOW_STATUS_SCHEDULE: 20,
            SUPPORT_FLOW_STATUS_PROGRESS: 30,
            SUPPORT_FLOW_STATUS_CONFIRM: 40,
            SUPPORT_FLOW_STATUS_CANCEL: 50,

            //support_progress_status_code
            PLAN: 10,
            SCHEDULE: 20,
            IN_PROGRESS: 30,
            CONFIRM: 40,
            isReload: false,
            navigation: {
                width: "0%",
                borderSize: 3
            },
            showAnimation: false,
            hiddenAnimation: false,
        };
    },
    mixins: [
        mixins
    ],
    components: {
        Dialog,
    },
    async created() {
        this.setupListSupport();
        // this.getCodeStatusSupport()
        this.getMasterFormSupport();
        this.getIntendDateList();
        this.getContinueSupportFormAList();
        this.getContinueSupportFormBList();
        this.getContinueSupportFormList();

        this.codeStatusSupport = await this.getCodeDefine(CODE_STATUS_SUPPORT)
    },
    props: {
        flows: null,
        time: null,
        isShowNavigation: {
            default: "",
        },
    },
    computed: {
        cssWidth() {
            return {
                "widthAnimation": this.navigation.width,
            }
        },

        // 初回面接の支援形態リスト (通常)
        firstSupportFormList() {
            return this.masterFormSupportList.filter(item => item.map_system_code_04 == "1");
        },

        // 初回面接の支援形態リスト (分割実施)
        firstSplitSupportFormList() {
            return this.masterFormSupportList.filter(item => item.map_system_code_04 == "2");
        },
    },
    watch: {
        async flows() {
            await this.filterFlowSupporting()
            await this.getFlowDetail(this.activeFlow.id);
            await this.calculate()
            await this.checkCanRecreateFlow()
            this.filterFlowSupporting()
        },
        async time() {
            await this.getFlowDetail(this.activeFlow.id);
        },
        isShowNavigation() {
            this.navigation.width = "80%";
            this.hiddenAnimation = false;
            this.showAnimation = true;

            $(".navigation").one("animationend", () => {
                this.showAnimation = false;
            });
        },
    },
    async mounted() {
        await this.filterFlowSupporting()
        if (this.activeFlow.id) {
            await this.getFlowDetail(this.activeFlow.id);
        }
        await this.calculate()
        await this.checkCanRecreateFlow()
        this.filterFlowSupporting()

        this.setBorderWidth();
        this.setEvents();
    },
    methods: {
        setErrors(e) {
            console.log(e);
        },
        planSupportTimeDisp(segment) {
            let date = segment?.plan_support_date ? segment?.plan_support_date.substr(-5).replace("-", "/") : "";
            let time = segment?.plan_support_time ? segment?.plan_support_time.substr(0,5) : ""

            if (date == time) return "-";
            return date + " " + time
        },
        scheduleSupportTimeDisp(segment) {
            let date = segment?.scheduled_support_date ? segment?.scheduled_support_date.substr(-5).replace("-", "/") : "";
            let time = segment?.scheduled_support_time ? segment?.scheduled_support_time.substr(0,5) : ""

            if (date == time) return "-";
            return date + " " + time
        },
        supportAmountDisp(segment) {
            let sp_form_name = segment?.health_support_form?.support_form_name_disp || "-";
            let sp_amount = segment?.support_amount || "-"
            let unit = segment?.health_support_form?.support_amount_unit || "-"

            if (sp_form_name == sp_amount) return "-"

            return sp_form_name + "、" + sp_amount + unit
        },
        scheduleAmountDisp(segment) {
            let sp_form_name = segment?.health_support_form?.support_form_name_disp || "-";
            let sp_amount = segment?.scheduled_support_amount || "-"
            let unit = segment?.health_support_form?.support_amount_unit || "-"

            if (sp_form_name == sp_amount) return "-"

            return sp_form_name + "、" + sp_amount + unit
        },
        supportAssignorUser(segment) {
            if (segment?.support_assignor_user) {
                return "[" + segment?.support_assignor_user?.id + "]" + segment?.support_assignor_user?.user_name_disp
            }
            return "-"
        },
        scheduleSupportStaffUser(segment) {
            if (segment?.schedule_support_staff_user) {
                return "[" + segment?.schedule_support_staff_user?.id + "]" + segment?.schedule_support_staff_user?.user_name_disp
            }
            return "-"
        },
        supportStaffUser(segment) {
            if (segment?.support_staff_user) {
                return "[" + segment?.support_staff_user?.id + "]" + segment?.support_staff_user?.user_name_disp
            }
            return "-"
        },
        async getFlowDetail(flow_id) {
            try {
                await DashboardRepository.getFlowDetail(flow_id).then(res => {
                    this.flow = res.data;
                    this.flow.health_support_flow_segments.forEach(function(seg) {
                        seg.scheduled_support_amount_org = seg.scheduled_support_amount;
                        seg.scheduled_support_point_org = seg.scheduled_support_point;
                        //seg.health_support_form_org = JSON.parse(JSON.stringify(seg.health_support_form));
                    });
                });
            } catch (e) {
                return this.setErrors(e);
            }
        },
        updateSupportFlowConfirm() {
            this.isShowConfirm = true;
        },

        /**
         * 分割実施の登録チェック
         * (1) 分割実施１回目を個別支援で行う場合には、２回の合計で20分以上の支援
         * (2) 分割実施１回目をグループ支援で行う場合には、２回の合計で80分以上の支援
         * (3) 分割実施２回目が電話かメールの場合は、１回目で個別支援２０分以上、グループ80分以上 (1)(2)を満たす
         */
        validateSplitFirstSupport() {
            let isSplitFirstSupport = false;
            let scheduledSupportAmount = 0;
            let firstHealthSupportForm = null;
            let secondHealthSupportForm = null;

            // 分割実施かどうかチェック
            for(let segment of this.flow?.health_support_flow_segments) {
                if (this.checkSplitFirstSupport(segment)) {
                    isSplitFirstSupport = true;

                    const healthSupportForm = [
                        ...this.firstSupportFormList,
                        ...this.firstSplitSupportFormList,
                        ...this.continueSupportForm,
                    ].find(item => item.id == segment.health_support_form_id);
                    if (!healthSupportForm) {
                        continue;
                    }

                    // 電話・メールは0分扱い
                    if (!this.checkHealthSupportFormPhone(healthSupportForm.support_form_code)
                    && !this.checkHealthSupportFormEmail(healthSupportForm.support_form_code)) {
                        scheduledSupportAmount += segment.scheduled_support_amount ?? 0;
                    }

                    // 分割実施 (1回目)
                    if (this.checkSplitFirstSupportFirst(segment)) {
                        firstHealthSupportForm = healthSupportForm;
                    } else if (this.checkSplitFirstSupportSecond(segment)) {
                        secondHealthSupportForm = healthSupportForm;
                    }
                }
            }

            // 分割実施でない
            if (!isSplitFirstSupport) {
                return true;
            }

            if (!firstHealthSupportForm || !secondHealthSupportForm) {
                this.notifyError("初回面接形態が未設定です");
                return false;
            }

            // 1回目: 個別支援
            if (firstHealthSupportForm
                && this.checkHealthSupportFormIndividual(firstHealthSupportForm.support_form_code)
                && scheduledSupportAmount < 20) {
                this.notifyError("初回支援は合計20分以上で設定してください");
                return false;
            }

            //  1回目: グループ支援
            if (firstHealthSupportForm
                && this.checkHealthSupportFormGroup(firstHealthSupportForm.support_form_code)
                && scheduledSupportAmount < 80) {
                this.notifyError("初回支援は合計80分以上で設定してください");
                return false;
            }

            return true;
        },

        /**
         * 支援フロー保存.
         */
        updateSupportFlow() {
            let formData = {
                flow_id: this.flow.id,
                delete_segment_ids: this.listDeleteSegmentID,
                segments: this.flow?.health_support_flow_segments
            }

            if (!this.validateSplitFirstSupport()) {
                this.isShowConfirm = false;
                return;
            }

            try {
                DashboardRepository.createSupportFlow(formData).then(res => {
                    this.isShowConfirm = false;
                    if(res.status) {
                        this.isReload = true;
                        this.getFlowDetail(this.activeFlow.id);
                        this.listDeleteSegmentID = []
                        this.notifySuccess("支援フローの登録処理が完了しました。");
                    } else {
                        this.notifyError("保存処理中に予期しないエラーが発生しました。 ");
                    }
                });
            } catch (e) {
                this.notifyError("保存処理中に予期しないエラーが発生しました。 ");
                return this.setErrors(e);
            }
        },
        /**
         * 支援フローが編集可能な場合true.
         */
        canEditFlow() {
            // 支援フローステータスが「開始前」or「支援実施中」　ならばtrue.
            return [this.BEFORE_START_SUPPORT, this.SUPPORT_IN_PROGRESS].indexOf(parseInt(this.flow.support_flow_status)) >= 0;
        },
        /**
         * 初回支援セグメント 編集可能ならばtrue.
         */
        canEditFirstSupportSegment(segment) {
            if (!this.canEditFlow()) return false;
            // 支援中止フラグがOFF、かつ進捗が「計画」or「予定」or「実施中」
            return !segment.support_stop_flag && [this.CODE_PLAN, this.CODE_INTEND, this.CODE_DOING].indexOf(parseInt(segment.support_progress_status)) >= 0;
        }, 
        /**
         * 継続支援セグメント 編集可能ならばtrue.
         */
        canEditContinuousSupportSegment(segment) {
            // 初回支援と同じ.
            return this.canEditFirstSupportSegment(segment);
        }, 
        /**
         * 最終評価セグメント 編集可能ならばtrue.
         */
        canEditLastSupportSegment(segment) {
            // 初回支援と同じ.
            return this.canEditFirstSupportSegment(segment);
        }, 
        confirmRecreateFlow() {
            this.isShowRecreateFlow = true;
        },
        async recreateFlowFunc() {
            this.isShowRecreateFlow = false;
            try {
                await DashboardRepository.recreateFlowFunc(this.flow.id, {flow_id: this.flow.id}).then(res => {
                    // this.$root.$emit("Dashboard.ReloadDataReception");
                    this.isReload = true;
                    this.getFlowDetail(this.activeFlow.id);
                    this.isShowRecreateFlow = false;
                    this.notifySuccess("支援フロー再作成処理が完了しました。");
                });
            } catch (e) {
                this.notifyError("処理中に予期しないエラーが発生しました。");
                return this.setErrors(e);
            }
        },
        /**
         * 支援フロー再作成可否チェック. 可能な場合true.
         */
        checkCanRecreateFlow() {
            let canRecreate = false;
            if (this.flow) {
                // 支援フローの編集可否.
                canRecreate = this.canEditFlow();
                // システム権限.
                canRecreate = canRecreate && this.isSystemAdminOrBackOffice();
                // セグメントの進捗ステータス.
                this.flow?.health_support_flow_segments.map(segment => {
                    // 継続支援or最終評価の場合
                    if (
                        parseInt(segment.support_exec_div) === this.CODE_CONTINUE_SUPPORT ||
                        parseInt(segment.support_exec_div) === this.CODE_FINAL_EVAL
                    ) {
                        // 進捗が「計画」or「予定」か判定
                        canRecreate = canRecreate && (
                            parseInt(segment.support_progress_status) === this.CODE_PLAN ||
                            parseInt(segment.support_progress_status) === this.CODE_INTEND
                        );
                    }
                })
            }

            this.can_recreate_flow = canRecreate;
        },
        filterFlowSupporting() {
            if (this.flows.length > 0) {
                this.activeFlow = JSON.parse(JSON.stringify(
                    this.flows[0]
                    // this.flows.find(flow => {
                    //     return parseInt(flow.support_flow_status) === this.CODE_FLOW_SUPPORTING
                    // })
                ))
            }
        },
        /**
         * 集計計算.
         */
        calculate() {
            if (this.flow) {
                const segments = this.flow?.health_support_flow_segments
                this.numOfPointSupport = 0;
                this.sumOfPointSupport = 0;
                this.numOfPointSupportA = 0;
                this.sumOfPointSupportA = 0;
                this.numOfPointSupportB = 0;
                this.sumOfPointSupportB = 0;

                // foreach内でthisを参照するため.
                const self = this;
                segments.forEach(function(segment) {
                    if (self.checkSupportA(segment)) {
                        self.numOfPointSupportA++;
                        const v = parseInt(segment.scheduled_support_point);
                        self.sumOfPointSupportA += v ? v : 0;
                    }
                    if (self.checkSupportB(segment)) {
                        self.numOfPointSupportB++
                        const v = parseInt(segment.scheduled_support_point);
                        self.sumOfPointSupportB += v ? v : 0;
                    }
                    if (self.checkSupport(segment)) {
                        self.numOfPointSupport++;
                        self.sumOfPointSupport += parseInt(segment.scheduled_support_point) ?? 0;
                    }
                });
            }
        },
        /**
         * 初回支援形態取得.
         */
        async getMasterFormSupport() {
            try {
                await DashboardRepository.getMasterFormSupport().then(res => {
                    this.masterFormSupportList = res.data.data;
                });
            } catch (e) {
                return this.setErrors(e);
            }
        },
        /**
         * 期間マスタ取得.
         */
        async getIntendDateList() {
            try {
                await DashboardRepository.getIntendDateList().then(res => {
                    this.intendDateList = res.data.data;
                });
            } catch (e) {
                return this.setErrors(e);
            }
        },

        /**
         * 支援A 支援形態取得.
         */
        async getContinueSupportFormAList() {
            try {
                await DashboardRepository.getContinueSupportFormList(this.CODE_SUPPORT_A).then(res => {
                    this.continueSupportFormA = res.data.data;
                });
            } catch (e) {
                return this.setErrors(e);
            }
        },

        /**
         * 支援B 支援形態取得
         */
        async getContinueSupportFormBList() {
            try {
                await DashboardRepository.getContinueSupportFormList(this.CODE_SUPPORT_B).then(res => {
                    this.continueSupportFormB = res.data.data;
                });
            } catch (e) {
                return this.setErrors(e);
            }
        },

        /**
         * 支援(4期) 支援形態取得
         */
        async getContinueSupportFormList() {
            try {
                await DashboardRepository.getContinueSupportFormList(this.CODE_SUPPORT).then(res => {
                    this.continueSupportForm = res.data.data;
                });
            } catch (e) {
                return this.setErrors(e);
            }
        },

        /**
         * セグメント追加.
         * @param {*} index 
         */
        addSegment(index) {
            let segments = this.flow?.health_support_flow_segments;
            if (segments.length > 99) return false; // can not add more 99 segment
            segments.splice(index+1, 0, {
                is_new: true,
                support_progress_status: this.CODE_PLAN,
                support_exec_div: this.CODE_CONTINUE_SUPPORT,
                support_point:0,
                mid_eval_flg: false,
                scheduled_support_point: null,
                scheduled_support_amount: null,
                scheduled_support_term: null,
                health_support_form_id: null,
                health_support_form: {
                    support_form_div: this.CODE_SUPPORT_A
                }
            });

            this.setDefaultSupportFormInfo(segments[index+1]);

            this.calculate()
        },
        /**
         * セグメント削除.
         * 
         * @param {*} index 
         * @param {*} confirm 
         */
        deleteSegment(index, confirm = false) {
            this.diaglogDeleteIndex = index;
            const segments = this.flow?.health_support_flow_segments;
            const delSegment = segments[index];

            if (delSegment.id && confirm === false && delSegment.support_progress_status == 20) {
                this.isShowDialogDelete = true;
            } else {
                if (segments[index].id !== null) {
                    this.listDeleteSegmentID.push(segments[index].id);
                }
                segments.splice(index, 1)
                this.isShowDialogDelete = false;
            }

            this.calculate()
        },
        /**
         * セグメント 上移動
         * @param {*} index 
         */
        upSegment(index) {
            let segments = this.flow?.health_support_flow_segments;
            if (this.checkSupportFirst(segments[index - 1])) return;
            if (this.segmentConfirm(segments[index])) return;
            if (this.segmentConfirm(segments[index-1])) return;
            $(".fade-" + index).addClass("elementToFadeInAndOut");
            $(".fade-" + (index - 1)).addClass("elementToFadeInAndOut");
            setTimeout(() => {
                let from = segments.splice(index, 1)[0];
                segments.splice(index - 1, 0, from)
            }, 800);
            setTimeout(() => {
                $(".fade-" + index).removeClass("elementToFadeInAndOut");
                $(".fade-" + (index - 1)).removeClass("elementToFadeInAndOut")
            }, 1500)
        },
        /**
         * セグメント 下移動
         * @param {*} index 
         */
        downSegment(index) {
            let segments = this.flow?.health_support_flow_segments;
            if (this.checkSupportFinal(segments[index + 1])) return;
            if (this.segmentConfirm(segments[index + 1])) return;
            if (this.segmentConfirm(segments[index])) return;
            $(".fade-" + index).addClass("elementToFadeInAndOut");
            $(".fade-" + (index + 1)).addClass("elementToFadeInAndOut");
            setTimeout(() => {
                let from = segments.splice(index, 1)[0];
                segments.splice(index + 1, 0, from);
            }, 800);
            setTimeout(() => {
                $(".fade-" + index).removeClass("elementToFadeInAndOut");
                $(".fade-" + (index + 1)).removeClass("elementToFadeInAndOut")
            }, 1500)
        },
        /**
         * 支援A or 支援B 実施量変更時 ポイント更新 (算出含む)
         * @param {*} index 一覧インデックス
         * @param {*} event イベント
         */
        updatePoint(index, event){
            const segments = this.flow?.health_support_flow_segments;
            // 支援形態マスタ取得.
            const health_support_form = this.showSupportForm(segments[index], this.continueSupportFormA, this.continueSupportFormB, this.continueSupportForm);
            // 算出ポイント設定.
            segments[index].scheduled_support_point = this.calcSupportPoint(health_support_form, event.target.value);

            // 集計.
            this.calculate();
        },
        /**
         * 支援ポイント算出
         * 
         * @param {*} supportForm 
         * @param {*} amount 
         */
        calcSupportPoint(supportForm, amount) {
            if(supportForm == null) return 0;
            // 基準介入量
            const basis_support_amount = supportForm?.basis_support_amount || 1;
            // 基準ポイント
            const basis_point = supportForm?.basis_point || 1;
            // ポイント上限
            const max_point = supportForm?.max_point || 0;
            // 対象セグメントの予定支援ポイントの更新
            if (basis_support_amount === 0) {
                return 0;
            } else{
                // 基準をもとに切り捨てたポイントの更新
                return Math.min(parseInt(amount / basis_support_amount) * basis_point, max_point);
            }
        },
        onHandleClickDropdown(index) {
            this.$refs[`container_support_${ index }`][0].classList.toggle("d-block");
        },
        /**
         * 支援形態区分 変更時.
         * @param {*} index 
         * @param {*} e 
         */
        changeTypeSupport(index, e) {
            const segments = this.flow?.health_support_flow_segments;
            const segment = segments[index];
            segment.health_support_form.support_form_div = e.target.value;
            this.setDefaultSupportFormInfo(segment);
           
            this.calculate();
        },
        /**
         * デフォルト支援形態情報設定.
         * @param {*} segment 
         */
        setDefaultSupportFormInfo(segment) {
            const defaultSupportForms = this.getContinuousSupportFormList(segment);
            const defaultSupportForm = defaultSupportForms[0];
            segment.health_support_form_id = defaultSupportForm.id;

            this.onContinuousSupportFormChanged(segment, defaultSupportForms);
        },
        /**
         * 継続支援 支援形態リスト取得.
         * @param {*} segment 
         */
        getContinuousSupportFormList(segment) {
            if (this.checkSupportA(segment)) {
                return this.continueSupportFormA;
            }
            if (this.checkSupportB(segment)) {
                return this.continueSupportFormB;
            }
            return this.continueSupportForm;
        },
        setupListSupport() {
            this.listSupport = [
                {
                    title: this.$t("dashboard_support.support_flow.edit.first_support"),
                    schedule: {
                        requester: "保健一郎",
                        manager: "支援花子",
                        schedule_date: "04/03",
                        place: "6th Element West Lake",
                        form: "個別面談(20分)",
                    },
                    implementation: {
                        manager: "支援花子",
                        schedule_date: "04/05",
                        form: "個別面談(20分)",
                    }
                },
                {
                    title: "支援A",
                    schedule: {
                        requester: "保健一郎",
                        manager: "支援花子",
                        schedule_date: "04/03",
                        form: "個別面談(20分)",
                        point: "40pt"
                    },
                    implementation: {
                        manager: "支援花子",
                        schedule_date: "04/05",
                        form: "個別面談(20分)",
                        point: "40pt"
                    }
                },
                {
                    title: this.$t("dashboard_support.support_flow.edit.final_evaluation"),
                    schedule: {
                        requester: "保健一郎",
                        manager: "支援花子",
                        schedule_date: "04/03",
                        form: "個別面談(20分)",
                        point: "40pt"
                    },
                    implementation: {
                        manager: "支援花子",
                        schedule_date: "04/05",
                        form: "個別面談(20分)",
                        point: "40pt"
                    }
                }
            ];
        },
        closeSlide() {
            this.hiddenAnimation = true;
            if (this.isReload) {
                this.$root.$emit("Dashboard.ReloadDataReception");
                this.isReload = false;
            }
            this.hideSlide();
        },
        /**
         * 対象セグメントが削除可能な場合true.
         * 
         * @param {*} segment 
         */
        checkCanDelete(segment){
            if (this.isNewAddedSegment(segment)) {
                return true;
            } else {
                return segment?.support_stop_flag == 0 &&
                    [this.CODE_PLAN, this.CODE_INTEND].indexOf(parseInt(segment?.support_progress_status)) >= 0 &&
                    (this.isSystemAdmin() || this.isBackOffice());
            }
        },
        /**
         * 後続支援追加可能判定 可能な場合true.
         * @param {*} index 
         */
        checkCanCreateSegmentAfter(segment, index) {
            if (!this.canEditFlow()) return false;
            // 次セグメント取得.
            const segments = this.flow?.health_support_flow_segments;
            const nextSegment = segments[index+1];

            // 次が2回目の初回支援のため不可
            if (this.checkSplitFirstSupportFirst(segment)) {
                return false;
            }

            return (nextSegment.support_stop_flag == 0 && [this.CODE_PLAN, this.CODE_INTEND, this.CODE_DOING].indexOf(parseInt(nextSegment.support_progress_status)) >= 0)
                ||
                this.isNewAddedSegment(nextSegment);
        },
        /**
         * 新規追加セグメントの場合true.
         */
        isNewAddedSegment(segment) {
            return !segment.support_stop_flag && !segment.support_progress_status
        },
        /**
         * 初回支援 支援形態取得
         * 
         * @param {*} segment 
         */
        showFirstSupportForm(segment) {
            let result = this.masterFormSupportList.filter(e => e.id == segment.health_support_form_id)
            if (result) {
                return result[0]
            }
            return null
        },
        /**
         * 支援A or 支援B 支援形態取得.
         * REFACTORING: findSupportFormに以降したい。
         * 
         * @param {*} segment 支援フローセグメント
         * @param {*} listA 支援A 支援形態
         * @param {*} listB 支援B 支援形態
         * @param {*} list4th 支援(4期) 支援形態
         */
        showSupportForm(segment, listA, listB, list4th) {
            let list = listA;
            if (this.checkSupportB(segment)) {
                list = listB;
            }
            if (this.checkSupport(segment)) {
                list = list4th;
            }
            let result = (list ?? []).filter(e => e.id == segment.health_support_form_id)
            if (result) {
                return result[0];
            }
            return null;
        },

        /**
         * 支援A or 支援B 支援形態取得.
         * 
         * @param {*} segment 支援フローセグメント
         * @param {*} listA 支援A 支援形態
         * @param {*} listB 支援B 支援形態
         * @param {*} list4th 支援(4期) 支援形態
         */
        findSupportForm(segment, list) {
            let result = (list ?? []).filter(e => e.id == segment.health_support_form_id)
            if (result) {
                return result[0];
            }
            return null;
        },

        /**
         * 継続支援 支援形態 変更時
         * 
         * @param {*} segment 
         * @param {*} supportForms 
         */
        onContinuousSupportFormChanged(segment, supportForms) {
            let supportForm = supportForms.filter(e => e.id == segment.health_support_form_id);
            if (!supportForm) return;
            supportForm = supportForm[0];

            // 介入量設定.
            segment.scheduled_support_amount = supportForm?.min_support_amount ?? 0;
            // 算出ポイント設定.
            segment.scheduled_support_point = this.calcSupportPoint(supportForm, segment.scheduled_support_amount);

            // 集計
            this.calculate();
        },

        ...mapMutations({
            hideSlide: "hideSlide"
        }),
        setBorderWidth() {
            let i = this.$refs.drawer.$el.querySelector(".v-navigation-drawer__border");
            i.style.width = this.navigation.borderSize + "px";
            i.style.cursor = "ew-resize";
            let containerContent = this.$refs.drawer.$el.querySelector(".container--fluid");
            containerContent.classList.value = "d-flex flex-column h-100";
        },
        setEvents() {
            const minSize = this.navigation.borderSize;
            const el = this.$refs.drawer.$el;
            const drawerBorder = el.querySelector(".v-navigation-drawer__border");
            const direction = el.classList.contains("v-navigation-drawer--right")
                ? "right"
                : "left";

            function resize(e) {
                document.body.style.cursor = "ew-resize";
                let f =
                    direction === "right"
                        ? document.body.scrollWidth - e.clientX
                        : e.clientX;
                el.style.width = f + "px";
            }

            drawerBorder.addEventListener(
                "mousedown",
                (e) => {
                    if (e.offsetX < minSize) {
                        el.style.transition = "initial";
                        document.addEventListener("mousemove", resize, false);
                    }
                },
                false
            );

            document.addEventListener(
                "mouseup",
                () => {
                    el.style.transition = "";
                    this.navigation.width = el.style.width;
                    document.body.style.cursor = "";
                    document.removeEventListener("mousemove", resize, false);
                },
                false
            );
        },
    }
};
</script>

<style lang="sass" scoped>
    @import "../../../styles/common"
    .pointer_cursor
        cursor : pointer

    hr
        margin: 0
    .border-black
        border: 1px solid #333
    .border-black-bottom
        border-bottom: 1px solid #333
    span.border-bottom-style
        border-bottom: 2px solid #cdcdcd
        width: 200px
        display: block
    .block-segment-gray
        padding-left: 6px
        position: relative
        &:after
            content: ""
            position: absolute
            top: 0
            left: 0
            width: 17px
            height: 100%
            background: gray
            border: 1px solid #333
    .block-segment-white
        padding-left: 6px
        position: relative
        &:after
            content: ""
            position: absolute
            top: 0
            left: 0
            width: 17px
            height: 100%
            background: #fff
            border: 1px solid #333

    .block-segment-blue
        padding-left: 6px
        position: relative
        &:after
            content: ""
            position: absolute
            top: 0
            left: 0
            width: 17px
            height: 100%
            background: #eedcf2
            border: 1px solid #333
            color: #3b043b
    .block-segment-pink
        padding-left: 6px
        position: relative
        &:after
            content: ""
            position: absolute
            top: 0
            left: 0
            width: 17px
            height: 100%
            background: pink
            border: 1px solid #333

    .block-segment-green
        padding-left: 6px
        position: relative
        &:after
            content: ""
            position: absolute
            top: 0
            left: 0
            width: 17px
            height: 100%
            background: green
            border: 1px solid #333
    .block-segment-white
        padding-left: 6px
        position: relative
        &:after
            content: ""
            position: absolute
            top: 0
            left: 0
            width: 17px
            height: 100%
            background: #fff
            border: 1px solid #333
    .container-form-support
        overflow: auto
        overflow-x: hidden
        font-size: 13px

        & .block-confirmed
            position: relative
            &:before
                position: absolute
                content: ""
                width: 2px
                height: 100%
                background: gray
                top: 0
                left: 0

        & .container
            padding-top: 0
            padding-bottom: 0
            padding-right: 0

            & .container-title
                border: solid .25px $backgroundHoverPaginationItem

            & .border-left
                border-left: solid thin $black

            & .panel-left
                height: 100%
                width: 1%
                top: 0
                left: 0
                background: rgb(60, 179, 113)

            & .panel-support
                border: .25px solid $backgroundDisabledPagination
                padding: 1.5rem

                & table
                    & tr td
                        font-size: 14px
                        padding: 8px 0

                    & tr:first-child
                        & td
                            border-bottom: 2px solid $backgroundDisabledPagination
    .ellipsis
        text-overflow: ellipsis
        white-space: nowrap

    .elementToFadeInAndOut
        animation-name: fadeInOut
        animation-duration: 1s
        animation-iteration-count: 2
        animation-direction: alternate-reverse
        animation-timing-function: ease-in-out
    

    @keyframes fadeInOut
        0%
            opacity: 0
        100%
            opacity: 1
    .sidebar-show
        animation: show 0.5s ease forwards

    .sidebar-hidden
        animation: hidden 0.5s ease forwards

    @media (max-width: 1025px)
        .navigation
            transform: translate(0%) !important
        .v-navigation-drawer--close
            visibility: unset

    @keyframes hidden
        from
            width: var(widthAnimation)
        to
            width: 0

    @keyframes show
        from
            width: 0
        to
            width: var(widthAnimation)
.position-relative-left
    .dropdown-menu
        margin-left: -152px !important

.left-end-width
    width: 6.0rem !important

</style>
