<template>
<div>
    <ul class="nav nav-tabs p-0" id="myTab" role="tablist">
        <li class="nav-item">
            <button
                class="nav-link active"
                id="a-tab"
                data-bs-toggle="pill"
                data-bs-target="#a"
                type="button"
                role="tab"
                aria-controls="a"
            >
                支援入力
            </button>
        </li>
    </ul>
</div>
</template>

<script>
export default {
    name: "TabContentInputSupport"
}
</script>

<style scoped>

</style>