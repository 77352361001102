<template>
    <div class="dashboard-person-information">
        <div class="person-information-title">対象者情報</div>
        <div class="body-person-information">
                <ul class="nav nav-tabs" id="pills-tab">
                    <li class="nav-item">
                        <button
                            class="nav-link active rounded-0"
                            id="personal-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#personal"
                            type="button"
                            role="tab"
                            aria-controls="personal"
                        >
                            対象者
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button
                            class="nav-link rounded-0"
                            id="reception-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#reception"
                            type="button"
                            role="tab"
                            aria-controls="health-check-result"
                        >
                            受付
                        </button>
                    </li>
                </ul>

                <div class="tab-content" id="pills-tabContent">
                    <!-- PERSONAL -->
                    <div
                        class="tab-pane fade show active"
                        id="personal"
                        role="tab"
                        aria-labelledby="personal-tab"
                    >
                        <span class="d-flex flex-row-reverse">
                            <button class="btn btn-sm btn-outline-primary mt-2 mr-2" @click="show('Detail')">対象者詳細</button>
                        </span>
                        <div>
                            <table v-if="patient" class="table table-responsive table-hover patient-table">
                                <tr>
                                    <td><p class="m-0 p-1 p1">対象者ID</p></td>
                                    <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{ String(patient.id).padStart(10, "0") }}</b></p></td>
                                </tr>
                                <tr>
                                    <td><p class="m-0 p-1 p1">氏名カナ</p></td>
                                    <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{patient.patient_name_kana}}</b></p></td>
                                </tr>
                                <tr>
                                    <td><p class="m-0 p-1 p1">氏名</p></td>
                                    <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{patient.patient_name}}</b></p></td>
                                </tr>
                                <tr>
                                    <td><p class="m-0 p-1 p1">生年月日</p></td>
                                    <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{formatDate(patient.patient_birthday, "YYYY-MM-DD")}}</b></p></td>
                                </tr>
                                <tr>
                                    <td><p class="m-0 p-1 p1">性別</p></td>
                                    <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{ patient_gender[patient.patient_gender] }}</b></p></td>
                                </tr>
                                <tr>
                                    <td><p class="m-0 p-1 p1">保険者</p></td>
                                    <td class="align-baseline"><p class="m-0 p-1 p2"><b>[{{patient?.client_organizations?.client_organ_code}}] {{patient?.client_organizations?.client_organ_name}}</b></p></td>
                                </tr>
                                <tr>
                                    <td><p class="m-0 p-1 p1">保険証番号</p></td>
                                    <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{patient.insured_card_number}}</b></p></td>
                                </tr>
                                <tr>
                                    <td><p class="m-0 p-1 p1">保険証記号</p></td>
                                    <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{patient.insured_card_symbol}}</b></p></td>
                                </tr>
                                <tr>
                                    <td><p class="m-0 p-1 p1">保険証枝番</p></td>
                                    <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{patient.insured_card_branch_code}}</b></p></td>
                                </tr>
                                <tr>
                                    <td><p class="m-0 p-1 p1">利用券整理番号</p></td>
                                    <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{patient.use_ticket_ref_number}}</b></p></td>
                                </tr>
                                <tr>
                                    <td><p class="m-0 p-1 p1">利用券有効期限</p></td>
                                    <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{formatDate(patient.use_ticket_effective_date, "YYYY-MM-DD")}}</b></p></td>
                                </tr>
                                <tr>
                                    <td><p class="m-0 p-1 p1">受診券整理番号</p></td>
                                    <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{patient.healthexam_ticket_ref_number}}</b></p></td>
                                </tr>
                                <tr>
                                    <td><p class="m-0 p-1 p1">連絡先(携帯)</p></td>
                                    <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{patient.patient_tel_01}}</b></p></td>
                                </tr>
                                <tr>
                                    <td><p class="m-0 p-1 p1">連絡先(自宅)</p></td>
                                    <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{patient.patient_tel_02}}</b></p></td>
                                </tr>
                                <tr>
                                    <td><p class="m-0 p-1 p1">連絡先(職場)</p></td>
                                    <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{patient.patient_tel_03}}</b></p></td>
                                </tr>
                                <tr>
                                    <td><p class="m-0 p-1 p1">連絡先(その他)</p></td>
                                    <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{patient.patient_tel_04}}</b></p></td>
                                </tr>
                                <tr>
                                    <td><p class="m-0 p-1 p1">メールアドレス</p></td>
                                    <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{patient.patient_mail_01}}</b></p></td>
                                </tr>
                                <tr>
                                    <td><p class="m-0 p-1 p1">郵便番号</p></td>
                                    <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{patient.patient_postal_code}}</b></p></td>
                                </tr>
                                <tr>
                                    <td><p class="m-0 p-1 p1">住所</p></td>
                                    <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{patient.patient_addr_01}}</b></p></td>
                                </tr>
                                <tr>
                                    <td><p class="m-0 p-1 p1">初回面接予定日時</p></td>
                                    <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{formatDate(patient.scheduled_first_support_datetime, 'YYYY-MM-DD HH:mm')}}</b></p></td>
                                </tr>
                                <tr>
                                    <td><p class="m-0 p-1 p1">取込日時</p></td>
                                    <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{formatDate(patient.healthexam_result_import_datetime, 'YYYY-MM-DD HH:mm:ss')}}</b></p></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <!-- RECEPTION -->
                    <div
                        class="tab-pane fade show"
                        id="reception"
                        role="tab"
                        aria-labelledby="reception-tab"
                    >
                        <span class="d-flex flex-row-reverse">
                            <button v-if="has_backoffice_auth" class="btn btn-sm btn-outline-primary mt-2 mr-2"  @click="show('ReceiptMedical')">受付詳細</button>
                        </span>


                        <div class="data-table-container">
                            <table v-if="healthGuidanceReception" class="table table-responsive table-hover reception-table">
                                <tr>
                                    <td><p class="m-0 p-1 p1">受付日</p></td>
                                    <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{formatDate(healthGuidanceReception.reception_date, 'YYYY-MM-DD')}}</b></p></td>
                                </tr>
                                <tr>
                                    <td><p class="m-0 p-1 p1">契約名称</p></td>
                                    <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{healthGuidanceReception.contract_name}}</b></p></td>
                                </tr>
                                <tr>
                                    <td><p class="m-0 p-1 p1">年度</p></td>
                                    <td class="align-baseline"><p class="m-0 p-1 p2">
                                        <b>{{healthGuidanceReception.contract_fiscal_year}}年度</b>
                                        &nbsp;<b>(第{{ healthGuidanceReception.health_guidance_period }}期)</b>
                                    </p></td>
                                </tr>
                                <tr>
                                    <td><p class="m-0 p-1 p1">契約区分</p></td>
                                    <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{code_health_guidance_contract_div[healthGuidanceReception.health_guidance_contract_div]}}</b></p></td>
                                </tr>
                                <tr>
                                    <td><p class="m-0 p-1 p1">保健指導区分</p></td>
                                    <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{code_health_guidance_div[healthGuidanceReception.health_guidance_div]}}</b></p></td>
                                </tr>
                                <tr>
                                    <td><p class="m-0 p-1 p1">本人家族区分</p></td>
                                    <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{code_person_family_div[healthGuidanceReception.person_familiy_div]}}</b></p></td>
                                </tr>
                                <tr>
                                    <td><p class="m-0 p-1 p1">支援パターン</p></td>
                                    <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{healthGuidanceReception?.health_support_pattern?.support_pattern_name}}</b></p></td>
                                </tr>
                                <tr>
                                    <td><p class="m-0 p-1 p1">初回面接形態</p></td>
                                    <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{healthGuidanceReception?.health_support_form?.support_form_name}}</b></p></td>
                                </tr>
                                <tr>
                                    <td><p class="m-0 p-1 p1">継続支援週数</p></td>
                                    <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{healthGuidanceReception?.continued_support_term}}</b></p></td>
                                </tr>
                                <tr>
                                    <td><p class="m-0 p-1 p1">保健指導機関</p></td>
                                    <td class="align-baseline"><p class="m-0 p-1 p2"><b>[{{healthGuidanceReception?.health_guidance_organization?.organ_code}}] {{healthGuidanceReception?.health_guidance_organization?.organ_name}}</b></p></td>
                                </tr>
                                <tr>
                                    <td><p class="m-0 p-1 p1">支払代行機関</p></td>
                                    <td class="align-baseline"><p class="m-0 p-1 p2"><b>[{{healthGuidanceReception?.payment_agency?.agency_code}}] {{healthGuidanceReception?.payment_agency?.agency_name}}</b></p></td>
                                </tr>
                                <tr>
                                    <td><p class="m-0 p-1 p1">契約取りまとめ機関</p></td>
                                    <td class="align-baseline"><p class="m-0 p-1 p2"><b>[{{healthGuidanceReception?.contract_coord_organization?.client_organ_code}}] {{healthGuidanceReception?.contract_coord_organization?.client_organ_name}}</b></p></td>
                                </tr>
                                <tr>
                                    <td><p class="m-0 p-1 p1">送付先機関</p></td>
                                    <td class="align-baseline"><p class="m-0 p-1 p2"><b>[{{healthGuidanceReception?.addressee_organiation?.client_organ_code}}] {{healthGuidanceReception?.addressee_organiation?.client_organ_name}}</b></p></td>
                                </tr>

                                <template v-if="has_backoffice_auth">
                                    <tr>
                                        <td><p class="m-0 p-1 p1">端数処理区分</p></td>
                                        <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{code_fraction[healthGuidanceReception.fractionating_div]}}</b></p></td>
                                    </tr>
                                    <tr>
                                        <td><p class="m-0 p-1 p1">契約単価(XMLデータ)</p></td>
                                        <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{ new Intl.NumberFormat('en-IN').format(healthGuidanceReception.contract_unit_price_01) }}</b></p></td>
                                    </tr>
                                    <tr>
                                        <td><p class="m-0 p-1 p1">契約単価(請求出力)</p></td>
                                        <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{new Intl.NumberFormat('en-IN').format(healthGuidanceReception.contract_unit_price_02)}}</b></p></td>
                                    </tr>
                                    <tr>
                                        <td><p class="m-0 p-1 p1">支払設定 開始時</p></td>
                                        <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{ parseInt(healthGuidanceReception.start_pyament_rate) }}%</b></p></td>
                                    </tr>
                                    <tr>
                                        <td><p class="m-0 p-1 p1">支払設定 最終評価</p></td>
                                        <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{ parseInt(healthGuidanceReception.final_eval_payment_rate) }}%</b></p></td>
                                    </tr>
                                    <tr>
                                        <td><p class="m-0 p-1 p1">支払設定 途中終了時</p></td>
                                        <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{ parseInt(healthGuidanceReception.retire_payment_rate) }}%</b></p></td>
                                    </tr>
                                </template>
                            </table>
                        </div>
                    </div>
                </div>
        </div>

        <slide>
            <Detail
                v-if="component === 'Detail'"
                :dataDetailId="patient.id"
                :checkViewOrEdit="false"
                :isShowNavigation="randomString"
            ></Detail>
            <ReceiptMedical
                v-if="component === 'ReceiptMedical'"
                :dataDetailId="patient.id"
                :dataReceptionsId="reception_id"
                :checkViewOrEdit="false"
                :isShowNavigation="randomString"
            />
        </slide>
    </div>
</template>
<style scoped>
    /*.tab-content {*/
    /*    min-height: 330px;*/
    /*    max-height: 330px;*/
    /*    overflow: scroll;*/
    /*    overflow-x: hidden;*/
    /*}*/
    .person-information-title {
        background-color: #cdcdcd;
        font-size: 13px;
        padding: 0 5px;
        border-radius: 3px 3px 0 0;
    }
</style>

<style lang="scss">
.dashboard-person-information {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 10px;

  .body-person-information {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    .tab-content {
      height: 100%;
      flex: 1 1 0;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}
</style>

<script>

import { mapMutations } from "vuex";
import DashboardService from "@/services/api/DashboardRepository";
import moment from "moment";
import Slide from "@/components/Slide";
import Detail from "@/components/DetailImportDiagnosticData";
import ReceiptMedical from "@/components/ReceiptMedicalDirectives/Modal/ReceiptMedical";
import Repository from "../../services/api/Repository";
import mixins from "@/mixins"
import {
    CODE_HEALTH_GUIDANCE_CONTRACT_DIV,
    CODE_HEALTH_GUIDANCE_DIV,
    CODE_FAMILY,
    CODE_FRACTION,
    CODE_GENDER,
    TOAST_TIME_OUT
} from "@/common/constants";
import {INTERNAL_SERVER_ERROR} from "@/constants";

export default {
    name: "DashboardPersonInformation",
    props: {
        reception_id: {
            default: 0
        }
    },
    mixins: [
        mixins
    ],
    data() {
        return {
            patient: null,
            healthGuidanceReception: null,
            component: undefined,
            code_health_guidance_contract_div: null,
            code_health_guidance_div: null,
            code_person_family_div: null,
            code_fraction: null,
            patient_gender: [],
            randomString: "",
            has_backoffice_auth: false,
        };
    },
    async created() {
        this.has_backoffice_auth = this.isSystemAdminOrBackOffice();
        this.patient_gender = await this.getCodeDefine(CODE_GENDER, "acronym");
        this.code_health_guidance_contract_div = await this.getCodeDefine(CODE_HEALTH_GUIDANCE_CONTRACT_DIV);
        this.code_health_guidance_div = await this.getCodeDefine(CODE_HEALTH_GUIDANCE_DIV);
        this.code_fraction = await this.getCodeDefine(CODE_FRACTION);
        this.code_person_family_div = await this.getCodeDefine(CODE_FAMILY);

        this.healthGuidanceReceptionDetail();
    },
    components: {
        Slide,
        Detail,
        ReceiptMedical,
    },
    mounted() {

        this.$root.$on("Dashboard.LoadPages", () => {
            this.healthGuidanceReceptionDetail();
        });
    },
    methods: {
        ...mapMutations({
            setIsLoading: "common/setIsLoading"
        }),
        async healthGuidanceReceptionDetail() {
            return await DashboardService.healthGuidanceReceptionDetail(this.reception_id).then(res => {
	            if(res.errorCode === INTERNAL_SERVER_ERROR) {
		            this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		            return;
	            }
                this.healthGuidanceReception = res.data.data;
                this.healthGuidanceReception.contract_fiscal_year = String(res.data.data.contract_fiscal_year).slice(-2);
                this.patient = this.healthGuidanceReception?.patients;
            })
        },
        formatDate(date, format = null) {
            if (date) {
                return moment(date).format(format ?? "YYYY-MM-DD HH:mm:ss");
            }
            return null;
        },
        show(component) {
            this.component = component;
            this.randomString = (Math.random() + 1).toString(36).substring(7);
        }
    }
}
</script>
<style lang="sass" scoped>
  @import "../../styles/common"

  .patient-table, .reception-table
    tr
        border-bottom: solid 1px
        border-color: #dee2e6
        border-bottom-width: 1px
  .p1
    width: 100px
    display: inline-block
    word-break: break-word
 .p2
    width: 260px
    display: inline-block
    word-break: break-word
</style>
