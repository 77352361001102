<template>
  <v-checkbox
    :id="id"
    :label="label"
    :color="color"
    :value="value"
    :readonly="readonly"
    :messages="messages"
    :error-messages="errorMessages"
    :disabled="disabled"
    @input="onHandleChangeValue"
    :hide-details="hideDetails"
  >
    <slot />
  </v-checkbox>
</template>

<script>
export default {
    name: "CheckBox",
    props: {
        value: {
            type: [String, Array],
            default: ""
        },
        color: {
            type: String,
            default: ""
        },
        disabled: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        messages: {
            type: String,
            default: ""
        },
        errorMessages: {
            type: String,
            default: ""
        },
        label: {
            type: String,
            default: ""
        },
        id: {
            type: String,
            default: ""
        },
        hideDetails: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            input: ""
        }
    },
    created() {
        this.input = this.value
    },
    methods: {
        onHandleChangeValue(e) {
            this.$emit("input", e)
        }
    }
};
</script>

<style scoped>

</style>