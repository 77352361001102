<template>
    <div class="h-100 d-flex flex-column">
        <div class="container-first-block" v-if="segmentBlockA">
            <div class="container-title mb-1 pl-2 pr-2" :class="classSegment(segmentBlockA)">
                <SegmentTitle :prefix="'支援欄1 '" :segment="segmentBlockA"></SegmentTitle>
            </div>

            <div class="p-2 body-first-block">
                <div class="d-flex justify-content-start align-content-start mt-3 flex-column gap-2" v-if="segmentBlockAEvals">
                    <table class="table table-bordered">
                        <tr v-for="(segmentEval, index) in segmentBlockAEvals" v-bind:key="index">
                            <td>{{segmentEval.eval_item_name_list_disp}}</td>
                            <td>
                                <div class="" style="max-width: 185px;" :class="{'click-detail': strlen(segmentEval?.eval_item_disp) > EVAL_ITEM_DISP_MAX_LEN_FOR_LIST}">
                                    <div class="overflow-hidden" style="word-break: break-all" v-if="strlen(segmentEval?.eval_item_disp) > EVAL_ITEM_DISP_MAX_LEN_FOR_LIST">
                                        {{ shortValue(segmentEval?.eval_item_disp) }}
                                        <div class="tooltip-info" @click="copyToClipboard(segmentEval?.eval_item_disp)">
                                            <v-tooltip right max-width="300" content-class="abc">
                                                <template v-slot:activator="{ on, attrs }">
                                                <span
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ><i class="fas fa-info-circle"></i></span>
                                                </template>
                                                <span style="width: 350px; word-break: break-all">{{segmentEval?.eval_item_disp}}</span>
                                            </v-tooltip>
                                        </div>
                                    </div>
                                    <template v-else>
                                        {{ shortValue(segmentEval?.eval_item_disp) }}
                                    </template>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>

        <div class="container-second-block" v-if="segmentBlockB">
            <div class="container-title mb-1 pl-2 pr-2" :class="classSegment(segmentBlockB)">
                <SegmentTitle :prefix="'支援欄2 '" :segment="segmentBlockB"></SegmentTitle>
            </div>
            <div class="p-2 body-first-block">
                <div class="d-flex justify-content-start align-content-start mt-3 flex-column gap-2" v-if="segmentBlockBEvals">
                    <table class="table table-bordered">
                        <tr v-for="(segmentEval, index) in segmentBlockBEvals" v-bind:key="index">
                            <td>{{segmentEval.eval_item_name_list_disp}}</td>
                            <td>
                                <div class="" style="max-width: 185px;" :class="{'click-detail': strlen(segmentEval?.eval_item_disp) > EVAL_ITEM_DISP_MAX_LEN_FOR_LIST}">
                                    <div class="overflow-hidden" style="word-break: break-all" v-if="strlen(segmentEval?.eval_item_disp) > EVAL_ITEM_DISP_MAX_LEN_FOR_LIST">
                                        {{ shortValue(segmentEval?.eval_item_disp) }}
                                        <div class="tooltip-info" @click="copyToClipboard(segmentEval?.eval_item_disp)">
                                            <v-tooltip right max-width="300" content-class="abc">
                                                <template v-slot:activator="{ on, attrs }">
                                                <span
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ><i class="fas fa-info-circle"></i></span>
                                                </template>
                                                <span style="width: 350px; word-break: break-all">{{segmentEval?.eval_item_disp}}</span>
                                            </v-tooltip>
                                        </div>
                                    </div>
                                    <template v-else>
                                        {{ shortValue(segmentEval?.eval_item_disp) }}
                                    </template>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>

        <Dialog
              :isShow="showDialog"
              :maxWidth="400"
              persistent
        >
              <v-card>
                  <v-container>
                      <v-card-title>ポップアップ</v-card-title>
                      <div class="m-3">{{dialogContent}}</div>
                      <v-card-actions class="d-flex align-content-center justify-content-center mr-2 ml-2">
                          <button type="button" class="btn btn-secondary" @click="showDialog = false">閉じる</button>
                      </v-card-actions>
                  </v-container>
              </v-card>
        </Dialog>
    </div>
</template>
<script>
import {mapGetters, mapMutations} from "vuex";
import DashboardService from "@/services/api/DashboardRepository";
import Repository from "../../services/api/Repository";
import {CODE_SUPPORT_EXEC_DIV,TOAST_TIME_OUT} from "@/common/constants";
import SegmentTitle from "@/components/dashboard/SegmentTitle";
import mixins from "@/mixins"
import Dialog from "@/components/common/Dialog";
import {INTERNAL_SERVER_ERROR} from "@/constants";

export default {
    name: "SupportInfo",
    data() {
        return {
            currentStatus: "初回支援",
            point: "60/180",
            segmentBlockAEvals: null,
            segmentBlockA: {},
            classSegmentBlockA: {},
            classSegmentBlockB: {},
            segmentBlockBEvals: null,
            segmentBlockB: {},
            code_support_exec_div: null,
            showDialog: false,
            dialogContent: "",
            EVAL_ITEM_DISP_MAX_LEN_FOR_LIST: 30
        };
    },
    components: {
        SegmentTitle,
        Dialog
    },
    async created() {
        // this.getCodeSupport();
        this.code_support_exec_div = await this.getCodeDefine(CODE_SUPPORT_EXEC_DIV);
    },
    mounted() {
        this.$root.$on("setSegmentBlockA", (data) => {
            this.getSegmengEval("blockA", data.id);
            this.segmentBlockA = data;
        });
        this.$root.$on("setSegmentBlockB", (data) => {
            this.getSegmengEval("blockB", data.id);
            this.segmentBlockB = data;
        });
    },
    mixins: [
        mixins
    ],
    methods: {
        ...mapMutations({
            setIsLoading: "common/setIsLoading"
        }),
        async getSegmengEval(type, segment_id) {
            return await DashboardService.getSegmentEval(segment_id).then(res => {
	            if(res.errorCode === INTERNAL_SERVER_ERROR) {
		            this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		            return;
	            }
                switch (type) {
                case "blockA":
                    this.segmentBlockAEvals = res.data.data;
                    break;
                case "blockB":
                    this.segmentBlockBEvals = res.data.data;
                    break;
                }
            })
        },
        shortValue(value) {
            if(typeof value == "string") {
                if (value.length > this.EVAL_ITEM_DISP_MAX_LEN_FOR_LIST) {
                    return value.substr(0, this.EVAL_ITEM_DISP_MAX_LEN_FOR_LIST);
                }
                return value;
            }
        },
        showDetail(content) {
            this.showDialog = true;
            this.dialogContent = content
        },
        strlen(str) {
            if(str == null) return ""
            
            return str.length
        },
    }
}
</script>
<style lang="scss" scoped>
    @import "../../styles/common";
    .click-detail {
      cursor: pointer;
      position: relative;

      .tooltip-info {
        color: #0d6efd;
        position: absolute;
        right: -10px;
        top: 25%;
      }
    }
    .container-first-block {
      height: 50%;
      overflow-y: auto;
      overflow-x: hidden;
      box-shadow: none !important;
      border-left: 1px solid #d9d9d9dd;
      border-radius: unset;
      display: flex;
      flex-direction: column;

      .container-title {
        background: #cdcdcd;
        span {
          font-size: 13px
        }
      }
      .body-first-block {
        flex: 1 1 0;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
    .container-second-block {
      height: 50%;
      overflow-y: auto;
      overflow-x: hidden;
      box-shadow: none !important;
      border-left: 1px solid #d9d9d9dd;
      border-radius: unset !important;
      display: flex;
      flex-direction: column;

      .container-title {
        background: #cdcdcd;
        span {
          font-size: 13px
        }
      }
      .body-first-block {
        flex: 1 1 0;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
</style>