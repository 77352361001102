<template>
  <div class="dashboard-health-result">
    <div class="person-information-title">健診結果</div>
    <div class="body-health-result">
        <ul class="nav nav-tabs position-relative" id="pills-tab">
          <li class="nav-item">
            <button
              class="nav-link active rounded-0"
              id="diagnostic-basics-tab"
              data-bs-toggle="pill"
              data-bs-target="#diagnostic-basics"
              type="button"
              role="tab"
              aria-controls="diagnostic-basics"
            >
              健診基本情報
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link rounded-0"
              id="test-category-tab"
              data-bs-toggle="pill"
              data-bs-target="#test-category"
              type="button"
              role="tab"
              aria-controls="test-category"
            >
              検査項目
            </button>
          </li>
          <button @click="exportPdf()" style="top: 0; right: 3px;" class="position-absolute p-1 pl-2 pr-2 btn btn-primary">
            <i class="fa fa-download" aria-hidden="true"></i>
          </button>
        </ul>

        <div class="tab-content" id="pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="diagnostic-basics"
            role="tab"
            aria-labelledby="diagnostic-basics-tab"
          >
            <div class="data-table-container">
              <table class="table table-responsive table-hover">
                <tr class="horizontal-border-line"
                  v-for="(item, index) in diagnostic_basic"
                  :key="
                    item + 'diagnostic_basic' + index + diagnostic_basic.length
                  "
                >
                  <td>
                    <p class="m-0 p-1">{{ item.healthexam_item_name_disp }}</p>
                  </td>
                  <td class="align-baseline">
                    <p class="m-0 p-1"><b>{{ item.healthexam_item_disp }}</b></p>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div
            class="tab-pane fade show"
            id="test-category"
            role="tab"
            aria-labelledby="test-category-tab"
          >
            <div class="data-table-container">
              <table class="table table-responsive table-hover">
                <tr class="horizontal-border-line"
                  v-for="(item, index) in test_category"
                  :key="
                    item.patient_healthexam_results_id +
                    '-test_category-' +
                    index
                  "
                  :class="{
                    color_level_10: item.judge_level == 10,
                    color_level_20: item.judge_level == 20,
                    color_level_30: item.judge_level == 30,
                  }"
                >
                  <td>
                    <p class="m-0 p-1">{{ item.healthexam_item_name_disp }}</p>
                  </td>
                  <td class="align-baseline">
                    <p class="m-0 p-1">
                      <span v-if="item.healthexam_item_disp.length > 0"><b>{{ item.healthexam_item_disp }}</b></span>
                      <span v-else>ー</span>
                    </p>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>
<style scoped>
/*.tab-content {*/
/*  min-height: 330px;*/
/*  max-height: 330px;*/
/*  overflow: scroll;*/
/*  overflow-x: hidden;*/
/*}*/
.person-information-title {
    background-color: #cdcdcd;
    font-size: 13px;
    padding: 0 5px;
    border-radius: 3px 3px 0 0;
}
</style>

<style lang="scss">
.dashboard-health-result {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 10px;

  .body-health-result {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;

    .tab-content {
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      flex: 1 1 0;
    }
  }
}
</style>


<script>
import { mapMutations } from "vuex";
import DashboardService from "@/services/api/DashboardRepository";
import {INTERNAL_SERVER_ERROR} from "@/constants";
import { TOAST_TIME_OUT, ENVS } from "@/common/constants";

export default {
    name: "HealthResult",
    props: {
        reception_id: {
            default: 0,
        },
    },
    data() {
        return {
            patient_id: null,
            diagnostic_basic: [],
            test_category: [],
        };
    },
    created() {
        this.healthGuidanceReceptionDetail();
    },
    watch: {
        reception_id() {
            this.healthGuidanceReceptionDetail();
        },
    },
    methods: {
        ...mapMutations({
            setIsLoading: "common/setIsLoading",
        }),
        exportPdf(){
            const link = document.createElement("a")
            link.href = `${ENVS.API_URL_BASE}/api/pdf-diagnostic-result/${this.patient_id}`;
            link.click()
        },

        async healthGuidanceReceptionDetail() {
            return await DashboardService.healthGuidanceReceptionDetail(
                this.reception_id
            ).then((res) => {
                const data = res.data.data;
	            if(res.errorCode === INTERNAL_SERVER_ERROR) {
		            this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		            return;
	            }
                if (data) {
                    this.patient_id = data.patient_id;
                    DashboardService.getHealthResult(data.patient_id).then(
                        (resHealthResult) => {
                            this.diagnostic_basic = [];
                            this.test_category = [];
                            resHealthResult.data.data.diagnostic_basic.map((x) => {
                                this.diagnostic_basic.push({
                                    healthexam_item_name_disp: x.healthexam_item_name_disp,
                                    healthexam_item_disp: x.healthexam_item_disp,
                                });
                            });
                            this.filterHasValueHealthexamResults(resHealthResult.data.data.diagnostic_list).map((x) => {
                                this.test_category.push({
                                    judge_level: x.judge_level,
                                    healthexam_item_name_disp: x.healthexam_item_name_disp,
                                    healthexam_item_disp: x.healthexam_item_disp,
                                });
                            });
                        }
                    );
                }
            });
        },
        /**
         * 健診結果値が存在する項目のみでフィルタ
         */
        filterHasValueHealthexamResults(list) {
            if (!list) return list;
            const self = this;
            return list?.filter(function(x, index, array) {
                return self.hasHealthexamResultValue(x.healthexam_item_disp);
            });
        },
        /**
         * 健診結果値が存在する場合true
         */
        hasHealthexamResultValue(v) {
            const trimedVal = v?.trim();
            return trimedVal && !trimedVal.startsWith("ー");
        },
    },
};
</script>
<style lang="sass" scoped>
@import "../../styles/common"
.horizontal-border-line
    border-bottom: solid 1px
    border-color: #dee2e6
    border-bottom-width: 1px
p
  width: 180px
  display: inline-block
  word-break: break-word
</style>
