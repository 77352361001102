<template>
    <!--  In case showTabs is different from support first, show tab-->
    <div class="dashboard-manager-support" v-if="showTabs !== 'support_first'">
          <ul class="nav nav-tabs p-0" id="myTab" role="tablist">
              <li class="nav-item">
                  <button
                          class="nav-link active"
                          id="aa-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#aa"
                          type="button"
                          role="tab"
                          aria-controls="aa"
                  >
                      対象者回答
                  </button>
              </li>
              <li class="nav-item">
                  <button
                          class="nav-link"
                          id="b-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#b"
                          type="button"
                          role="tab"
                          aria-controls="b"
                  >
                      メッセージ
                  </button>
              </li>
              <li class="nav-item">
                  <button
                          class="nav-link"
                          id="c-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#c"
                          type="button"
                          role="tab"
                          aria-controls="c"
                  >
                      架電記録
                  </button>
              </li>
          </ul>

          <div class="tab-content border p-2 border-top-0" id="myTabContent">
              <div
                      class="tab-pane fade show active"
                      id="aa"
                      role="tab"
                      aria-labelledby="aa-tab"
              >
                  <table class="table-responsive table table-bordered table-info-l4" v-if="segmentData">
                      <tr>
                          <th class="pt-2 pb-2">項目</th>
                          <th>値</th>
                      </tr>
                      <tr v-for="(item, key) in segmentData?.list_input_l4" v-bind:key="key">
                          <td class="pt-2 pb-2">
                              {{item}}
                          </td>
                          <td><span style="white-space: break-spaces;">{{valueAndText(segmentData, key)[0]?.eval_item_disp}}</span></td>
                      </tr>
                      <tr>
                          <td>対象者からの返答</td>
                          <td>
                              <span style="white-space: break-spaces;word-break: break-all;" v-html="segmentData?.last_feedback?.content"></span>
                          </td>
                      </tr>
                  </table>
              </div>
              <div
                      class="tab-pane fade show"
                      id="b"
                      role="tab"
                      aria-labelledby="b-tab"
              >
                  <ul class="nav nav-tabs p-0" id="myCTab" role="tablist">
                      <li class="nav-item">
                          <button
                            @click="clearDataForm('mail')"
                                  class="nav-link active"
                                  id="b1-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#b1"
                                  type="button"
                                  role="tab"
                                  aria-controls="b1"
                          >
                              メール
                          </button>
                      </li>
                      <li class="nav-item">
                          <button
                            @click="clearDataForm('sms')"
                                  class="nav-link"
                                  id="b2-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#b2"
                                  type="button"
                                  role="tab"
                                  aria-controls="b2"
                          >
                              ショートメール
                          </button>
                      </li>
                      <li class="nav-item">
                          <button
                            @click="clearDataForm('line')"
                                  class="nav-link"
                                  id="b3-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#b3"
                                  type="button"
                                  role="tab"
                                  aria-controls="b3"
                          >
                              LINE
                          </button>
                      </li>
                      <li class="nav-item">
                          <button
                                  class="nav-link"
                                  id="b4-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#b4"
                                  type="button"
                                  role="tab"
                                  aria-controls="b4"
                          >
                              メモ
                          </button>
                      </li>
                  </ul>
                  <div class="tab-content border border-top-0" id="myCTabContent">
                      <!-- start メールフォーム -->
                      <div
                              class="tab-pane fade show active"
                              id="b1"
                              role="tab"
                              aria-labelledby="b1-tab"
                      >
                          <div class="ml-2 mr-2 mb-2">
                              <div class="row">
                                  <div class="col-12">
                                      <div class="input-group d-flex justify-content-center align-items-center">
                                          送信先 <span class="pl-1 color-red">*</span>
                                          <select :disabled="tabControl.message" :value="emailForm.to" @change="onChangeReceiver" class="form-select ml-3">
                                              <option value=""></option>
                                              <option v-if="patient?.patient_mail_01"
                                                      :value="patient?.patient_mail_01">
                                                  {{patient?.patient_mail_01}}
                                              </option>
                                              <option v-if="patient?.patient_mail_02"
                                                      :value="patient?.patient_mail_02">
                                                  {{patient?.patient_mail_02}}
                                              </option>
                                              <option :value="mail_other">
                                                直接指定
                                              </option>
                                          </select>
                                      </div>
	                                  <span class="text-danger">{{ emailMsgFormErr.to }}</span>
                                  </div>
                              </div>
                              <div class="row" v-if="emailForm.to == mail_other">
                                  <div class="col-12">
                                    <input :disabled="tabControl.message" :maxlength="100" type="email" class="form-control" id="received_email_other"
                                           :value="emailForm.received_email_other" @input="onChangeReceivedOtherEmail">
                                    <span class="text-danger">{{emailMsgFormErr.received_email_other}}</span>
                                  </div>
                              </div>
                              <div class="row" v-if="contactListTemplate">
                                  <div class="col-12">
                                      <div class="input-group d-flex justify-content-center align-items-center">
                                          テンプレート
                                          <select :disabled="tabControl.message || !isValidReceiverForEmail" v-model="emailForm.contact_template"
                                                  class="form-select ml-3"
                                                  @change="changeContactList($event)">
                                              <option :value="null"></option>
                                              <option v-for="(contact, index) in contactListTemplate" v-show="contact.type == 'mail'" :key="index"
                                                      :value="index">{{contact.name}}
                                              </option>
                                          </select>
                                      </div>
                                  </div>
                                  <!-- <div class="col-12">
                                      <div class="input-group d-flex justify-content-center align-items-center">
                                          Form template:
                                          <select :disabled="tabControl.message" v-model="emailForm.form_template" class="form-select ml-3">
                                              <option :value="null"></option>
                                              <option value="" v-for="(contact, index) in formListTemplate"
                                                      :key="index">{{contact.name}}
                                              </option>
                                          </select>
                                      </div>
                                  </div> -->
                              </div>
	                          <div class="row">
		                          <div class="col-12">
			                          <label for="email-title">タイトル</label>
			                          <span class="ml-2">( {{ emailForm.subject?.length ?? 0 }} / 100 )</span>
			                          <span class="pl-1 color-red">*</span>
			                          <input :disabled="tabControl.message || !isValidReceiverForEmail" :maxlength="100" type="text" class="form-control" id="email-title"
			                                 v-model="emailForm.subject">
			                          <span class="text-danger">{{ emailMsgFormErr.subject }}</span>
		                          </div>
	                          </div>
	                          <div class="row">
		                          <div class="col-12" v-if="contactListTemplate">
			                          <button v-if="!tabControl.message" :disabled="!isValidReceiverForEmail" class="btn btn-primary btn-sm" @click="showGenerateLinkConfirmDialog('email')">URL生成
			                          </button>
		                          </div>
	                          </div>
                              <div class="row">
                                  <div class="col-12">
                                      <label for="email-content">本文入力</label>
	                                  <span class="ml-2">( {{ emailForm.content?.length ?? 0 }} / 2000 )</span>
	                                  <span class="pl-1 color-red">*</span>
                                      <textarea :disabled="tabControl.message" :maxlength="2000" name="" v-model="emailForm.content" id="email-content"
                                                class="form-control cursor-auto" cols="30" rows="10"></textarea>
	                                  <span class="text-danger">{{ emailMsgFormErr.content }}</span>
                                  </div>
                              </div>
                              <hr>
                              <div class="row">
                                  <div class="col-4">
                                      <button v-if="!tabControl.message" type="button"
                                              class="btn btn-secondary btn-sm text-nowrap"
                                              @click="isDialogBtnMailDraft=true">下書き保存
                                      </button>
                                  </div>
                                  <div class="col-8">
                                      <div class="row">
                                          <div class="col-8 p-0">
                                              <select :disabled="tabControl.message" class="form-select form-select-sm" v-model="emailForm.type" @change="typeSendMessage($event, 'email')" >
                                                  <option value="now">今すぐ</option>
                                                  <option value="schedule">予約</option>
                                              </select>
                                              <date-picker
                                                  :disabled="tabControl.message"
                                                  v-if="emailForm.type === 'schedule'"
                                                  autocomplete="off"
                                                  v-model="emailForm.schedule"
                                                  :config="option_scheduled_send_message_datetime"
                                                  name="emailForm.schedule"
                                              >
                                              </date-picker>
	                                          <span class="text-danger">{{ emailMsgFormErr.schedule }}</span>
                                          </div>
                                          <div class="col-3 p-0 d-flex align-items-start justify-content-center">
                                              <button v-if="!tabControl.message" type="button"
                                                      @click="isDialogBtnMail = true"
                                                      class="btn btn-primary btn-sm text-nowrap"
                                              >送信
                                              </button>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <!-- end メールフォーム -->
                      <!-- start SMSフォーム -->
                      <div
                          class="tab-pane fade show"
                          id="b2"
                          role="tab"
                          aria-labelledby="b2-tab"
                      >
                          <div class="ml-2 mr-2 mb-2">
                              <div class="row">
                                  <div class="col-12">
                                      <div class="input-group d-flex justify-content-center align-items-center">
                                          送信先 <span class="pl-1 color-red">*</span>
                                          <select :disabled="tabControl.message" v-model="emailForm.to" class="form-select ml-3" @change="changeSMSFormTo($event)">
                                              <option v-if="patient?.patient_tel_01 != '' && patient?.patient_tel_01 != null" :value="patient?.patient_tel_01"> 携帯 </option>
                                              <option v-if="patient?.patient_tel_02 != '' && patient?.patient_tel_02 != null" :value="patient?.patient_tel_02"> 自宅 </option>
                                              <option v-if="patient?.patient_tel_03 != '' && patient?.patient_tel_03 != null" :value="patient?.patient_tel_03"> 職場 </option>
                                              <option v-if="patient?.patient_tel_04 != '' && patient?.patient_tel_04 != null" :value="patient?.patient_tel_04"> その他 </option>
                                              <option :value="mail_other"> 直接指定 </option>
                                          </select>
                                      </div>
	                                  <span class="text-danger">{{ smsMsgFormErr.to }}</span>
                                  </div>
                              </div>
                            <div class="row" v-if="emailForm.to">
                              <div class="col-12">
                                <input :disabled="tabControl.message || emailForm.to != mail_other" :minlength="0" :maxlength="100" type="number" class="form-control phone-number"
                                       v-on:keydown="numbersOnly" :value="emailForm.received_email_other" @input="onChangeSMS">
                                <span class="text-danger">{{ smsMsgFormErr.received_email_other }}</span>
                              </div>
                            </div>
                              <div class="row" v-if="contactListTemplate">
                                  <div class="col-12">
                                      <div class="input-group d-flex justify-content-center align-items-center">
                                          テンプレート
                                          <select :disabled="tabControl.message || !isValidReceiverForSMS" v-model="emailForm.contact_template"
                                                  class="form-select ml-3"
                                                  @change="changeContactList($event)">
                                              <option :value="null"></option>
                                              <option v-for="(contact, index) in contactListTemplate" :key="index"
                                                v-show="contact.type == 'sms'" :value="index">{{contact.name}}
                                              </option>
                                          </select>
                                      </div>
                                  </div>
                                  <!-- <div class="col-12">
                                      <div class="input-group d-flex justify-content-center align-items-center">
                                          Form template:
                                          <select :disabled="false" v-model="emailForm.form_template" class="form-select ml-3">
                                              <option :value="null"></option>
                                              <option value="" v-for="(contact, index) in formListTemplate"
                                                      :key="index">{{contact.name}}
                                              </option>
                                          </select>
                                      </div>
                                  </div> -->
                                  <div class="col-12">
                                      <button v-if="!tabControl.message" :disabled="!isValidReceiverForSMS" class="btn btn-primary btn-sm" @click="showGenerateLinkConfirmDialog('sms')">URL生成
                                      </button>
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="col-12">
                                      <label for="email-content">本文入力</label>
	                                  <span class="ml-2">( {{ emailForm.content?.length ?? 0 }} / 660 )</span>
	                                  <span class="pl-1 color-red">*</span>
                                      <textarea :disabled="false" :maxlength="660" name="" v-model="emailForm.content" id="sms-content"
                                                class="form-control" cols="30" rows="10"></textarea>
	                                  <span class="text-danger">{{ smsMsgFormErr.content }}</span>
                                  </div>
                              </div>
                              <hr>
                              <div class="row">
                                  <div class="col-4">
                                      <button v-if="!tabControl.message" type="button"
                                              class="btn btn-secondary btn-sm text-nowrap"
                                              @click="isDialogBtnSMSDraft = true">下書き保存
                                      </button>
                                  </div>
                                  <div class="col-8">
                                      <div class="row">
                                          <div class="col-8 p-0">
                                              <select :disabled="false" class="form-select form-select-sm" v-model="emailForm.type" @change="typeSendMessage($event, 'sms')">
                                                  <option value="now">今すぐ</option>
                                                  <option value="schedule">予約</option>
                                              </select>
                                              <date-picker
                                                      v-if="emailForm.type === 'schedule'"
                                                      autocomplete="off"
                                                      v-model="emailForm.schedule"
                                                      :config="option_scheduled_send_message_datetime"
                                                      name="emailForm.schedule"
                                              >
                                              </date-picker>
	                                          <span class="text-danger">{{ smsMsgFormErr.schedule }}</span>
                                          </div>
                                          <div v-if="!tabControl.message" class="col-3 p-0 d-flex align-items-start justify-content-center">
                                              <button type="button"
                                                      @click="isDialogBtnSMS = true"
                                                      class="btn btn-primary btn-sm text-nowrap"
                                              >送信
                                              </button>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <!-- end SMSフォーム -->
                      <!-- start LINEフォーム -->
                      <div
                          class="tab-pane fade show"
                          id="b3"
                          role="tab"
                          aria-labelledby="b3-tab"
                      >
                      <div class="ml-2 mr-2 mb-2">
                          <div class="row">
                              <div class="col-12">
                                <label for="lineUserName">LINE ID <span class="pl-1 color-red">*</span></label>
                                <input :disabled="tabControl.message" type="text" class="form-control" id="lineUserName"
                                       v-model="lineUserName" @click="isDialogLine=true" readonly>
	                            <span class="text-danger">{{ lineMsgFormErr.lineUserName }}</span>
                              </div>
                              </div>
                              <div class="row" v-if="contactListTemplate">
                                  <div class="col-12">
                                      <div class="input-group d-flex justify-content-center align-items-center">
                                          テンプレート
                                          <select :disabled="tabControl.message || !isValidReceiverForLine" v-model="lineMsgForm.contact_template"
                                                  class="form-select ml-3"
                                                  @change="changeContactTemplate">
                                              <option :value="null"></option>
                                              <option v-for="(contact, index) in contactListTemplate" :key="index"
                                              v-show="contact.type == 'line'" :value="index">{{contact.name}}
                                              </option>
                                          </select>
                                      </div>
                                  </div>
                                  <!-- <div class="col-12">
                                      <div class="input-group d-flex justify-content-center align-items-center">
                                          Form template:
                                          <select :disabled="tabControl.message" v-model="lineMsgForm.form_template" class="form-select ml-3">
                                              <option :value="null"></option>
                                              <option value="" v-for="(contact, index) in formListTemplate"
                                                      :key="index">{{contact.name}}
                                              </option>
                                          </select>
                                      </div>
                                  </div> -->
                                  <div class="col-12">
                                      <button v-if="!tabControl.message" :disabled="!isValidReceiverForLine" class="btn btn-primary btn-sm" @click="showGenerateLinkConfirmDialog('line')">URL生成
                                      </button>
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="col-12">
                                      <label for="line-message-content">本文入力</label>
	                                  <span class="ml-2">( {{ lineMsgForm.content?.length ?? 0 }} / 2000 )</span>
	                                  <span class="pl-1 color-red">*</span>
                                      <textarea :disabled="tabControl.message" v-model="lineMsgForm.content" id="line-message-content" :maxlength="2000"
                                              class="form-control" cols="30" rows="10" placeholder="本文入力">
                                      </textarea>
                                      <span class="text-danger" v-if="lineMsgFormErr.content && (!lineMsgForm.content || !lineMsgForm.content.length)">{{ lineMsgFormErr.content }}</span>
                                  </div>
                              </div>
                              <div class="a d-flex align-items-center">
                                  <div class="col-4">
                                      <button v-if="!tabControl.message" type="button"
                                              class="btn btn-secondary btn-sm mt-3 text-nowrap"
                                              @click="isDialogBtnLineDraft = true">下書き保存
                                      </button>
                                  </div>
                                  <div class="col-8">
                                      <div class="row">
                                          <div class="col-8 p-0">
                                              <select :disabled="tabControl.message" class="form-select form-select-sm" v-model="lineMsgForm.status" @change="typeSendMessage($event, 'line')">
                                                <option value="now">今すぐ</option>
                                                <option value="schedule">予約</option>
                                              </select>
                                              <date-picker
                                                  v-if="lineMsgForm.status === 'schedule'"
                                                  :disabled="tabControl.message"
                                                  autocomplete="off"
                                                  v-model="lineMsgForm.schedule"
                                                  :config="option_scheduled_send_message_datetime"
                                                  name="emailForm.schedule"
                                              >
                                              </date-picker>
	                                          <span class="text-danger">{{ lineMsgFormErr.schedule }}</span>
                                          </div>
                                          <div class="col-3 p-0 d-flex align-items-start justify-content-center">
                                              <button v-if="!tabControl.message" type="button"
                                                      @click="isDialogBtnLine = true"
                                                      class="btn btn-primary btn-sm text-nowrap"
                                              >送信
                                              </button>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <!-- end LINEフォーム -->
                      <!-- start メモフォーム -->
                      <div
                              class="tab-pane fade show"
                              id="b4"
                              role="tab"
                              aria-labelledby="b4-tab"
                      >
	                    <div class="row">
		                  <div class="col-12">
	                        <span class="ml-2 mt-2">本文入力( {{ countMemoFormSupport }} / 5000 )<span class="pl-1 color-red">*</span></span>
		                  </div>
	                    </div>
                        <VueEditor :disabled="tabControl.message" class="m-2" :editorToolbar="vue2EditorCustomize" name="memoForm.support_memo" v-model="memoForm.support_memo" ref="editor" @input="countMemo"/>
                        <span class="pl-2 text-danger">{{ memoMsgFormErr }}</span>
                        <div class="row">
                          <div class="col-12">
                            <button v-if="!tabControl.message" type="button" class="btn btn-primary float-right mr-2" @click="submitSendMemo">保存</button>
                          </div>
                        </div>
                      </div>
                      <!-- end メモフォーム -->
                      <!-- start 履歴 -->
                      <div class="p-2">
                          <div v-for="(item, index) in mailLogList" :key="index">
                              <!-- Displayed when (status is 'draft' and sender is logged in user) or status is not draft -->
                              <div class="border border-5 mt-5 mb-5 p-0 box-log" v-if="(item.sender_id == user.id && item.status == 'draft') || item.status != 'draft'">
                                  <div :class="getClassLog(item)">
                                      <div class="row mb-2 p-2" style="position: relative">
                                          <div class="col text-nowrap ml-2 p-0">
                                            <span>{{item.send_time}}</span>
                                          <span style="position: absolute; top: 10px; right: 20px; font-size: large" v-if="item.type == 'memo'" @click="isShowDialogDeleteMemo = true, message_log_id = item.id" class="pl-5 c-pointer"><i class="fa fa-trash text-black" aria-hidden="true"></i></span>
                                          </div>
                                          <div class="col text-nowrap ml-2 p-0"><span v-if="item.type != 'memo' && !item.sender_name && item.received_id">{{typeOfMessageLog(item.type, item.sender_id == null ? "received" : "send")}}</span></div>
                                          <div class="col text-nowrap ml-2 p-0">{{ item.sender_id ? "送信者：" + item.sender_item?.user_name + " ("+ item.sender_item?.get_code_define?.value +")" : "" }}</div>
                                          <div class="col text-nowrap ml-2 p-0">
                                              <template v-if="item.received_id == null"></template>
                                              <template v-else-if="item.received_id && !item.sender_name">
                                                  <template v-if="item.type == 'sms'">
                                                  送信先：{{getReceivedEmail(item.received_email)}}
                                                  </template>
                                                  <template v-else-if="item.type == 'line'">
                                                    To：{{item.patient_line?.line_user_name}}
                                                  </template>
                                                  <template v-else>
                                                    <span v-if="item.received_email">To： {{item.received_email}}</span>
                                                  </template>
                                              </template>
                                          </div>
                                      </div>
                                      <hr class="m-0">
                                      <div class="pl-2 pt-0 status" v-if="item.sender_id != null || item.received_id != null">
                                          {{ checkStatus(item.status, item.time_send_message_schedule, item) }}
                                      </div>
<!--                                      <div class="pl-2 pt-0 status" v-else>-->
<!--                                        <span v-if="item.type == 'feedback'">回答</span>-->
<!--                                        <span v-else-if="item.received_id == null && item.status != 'draff' && item.status != 'schedule'">問い合わせ</span>-->

<!--                                      </div>-->
                                      <div class="pl-2 pt-0 mt-3 status"
                                      v-if="(item.sender_id != null || item.received_id != null) && item.status == 'error' && item.error_code">
                                          {{getMessError(item.error_code, item.error_message, item.type)}}
                                      </div>
                                  </div>

                                  <hr class="m-0" v-if="item.sender_id != null || item.received_id != null">
                                  <div class="p-2" v-if="item.sender_id != null || item.received_id != null">
                                      <p class="pb-0 mb-0" v-if="item.type != 'sms'">{{item.title}}</p>
                                      <hr class="mt-1 mb-1" v-if="item.type != 'sms'">
                                      <span v-html="revertStringWithLink(item.content)"></span>
                                  </div>
                                  <div v-else>
                                      <template v-if="item.content">
                                          <div class="p-2">
                                              <hr class="mt-1 mb-1">
                                              <span style="white-space: break-spaces; word-break: break-all" v-html="revertStringWithLink(item.content)"></span>
                                          </div>
                                      </template>
                                  </div>
                              </div>
                          </div>
                      </div>
                       <!-- end 履歴 -->
                  </div>
              </div>
              <div
                      class="tab-pane fade show"
                      id="c"
                      role="tab"
                      aria-labelledby="c-tab"
              >
                <div v-if="(dataTelRecord.id || !tabControl.call_record)">
                  <div class="col-12 pl-0 pr-0">
                      架電日
                      <date-picker
                        autocomplete="off"
                        :disabled="tabControl.call_record"
                        :config="optionDate"
                        name="tel_datetime"
                        v-model="dataTelRecord.tel_datetime"
                      >
                      </date-picker>
                      <span class="text-danger"></span>
                  </div>

                  <div class="col-12 pl-0 pr-0 pt-0">
                      架電先
                      <div class="input-group mb-3">
                          <select
                            :disabled="tabControl.call_record"
                            class="form-select"
                            name="tel_type"
                            style="max-width: 100px"
                            v-model="dataTelRecord.tel_type"
                            @change="checkRecommendTelType(dataTelRecord)"
                          >
                              <option v-for="contact in contact_form" :key="contact.value + 'contact'" :value="contact.value">
                                {{ contact.text }}
                              </option>
                          </select>
                          <input
                            :disabled="tabControl.call_record || disable_input_tel"
                            type="text"
                            class="form-control"
                            name="tel"
                            v-model="dataTelRecord.tel"
                          />
                      </div>
                  </div>

                  <div class="col-12 pl-0 pr-0 pt-0">
                      結果
                      <select
                        :disabled="tabControl.call_record"
                        class="form-select"
                        name="tel_result"
                        v-model="dataTelRecord.tel_result"
                      >
                          <option value=""></option>
                          <option v-for="telResult in telResultArr" :key="telResult.value"
                                  :value="telResult.key">
                              {{ telResult.value }}
                          </option>
                      </select>
                      <span class="text-danger"></span>
                  </div>
                  <div class="col-12 pl-0 pr-0 pt-0">
                      メモ
                      <textarea
                        :disabled="tabControl.call_record"
                        name="tel_memo"
                        cols="30" rows="3"
                        class="form-control"
                        v-model="dataTelRecord.tel_memo"
                      ></textarea>
                      <span class="text-danger"></span>
                  </div>
                </div>
                  <div v-if="!tabControl.call_record" class="call-recording-main-plus">
                      <div class="col-12 pl-0 pr-0">
                          <div class="d-flex justify-content-end">
                              <button type="button" @click="addTelRecord()"
                                      class="btn btn-success btn-sm">
                                  <i class="fa fa-plus mr-2" aria-hidden="true"></i>追加
                              </button>
                          </div>
                      </div>
                  </div>
                  <template v-if="segmentsTelRecordList">
                      <div class="call-recording-item border m-1"
                           v-for="(item, i) in segmentsTelRecordList" :key="i + 'a'">
                          <div class="row call-recording-main-trash">
                              <div class="d-flex justify-content-end">
                                  <button @click="deleteFormCallRecordDig(i, item.id)" type="button"
                                          class="btn btn-second">
                                      <i class="fa fa-trash text-danger" aria-hidden="true"></i>
                                  </button>
                              </div>
                          </div>
                          <div class="row m-2">
                              <div class="col-12">
                                  架電日
                                  <!-- option_scheduled_first_support_datetime -->
                                  <date-picker autocomplete="off"
                                    :disabled="tabControl.call_record"
                                    :config="optionDate"
                                    v-model="item.tel_datetime"
                                    name="tel_datetime"
                                  >
                                  </date-picker>
                                  <span class="text-danger"></span>
                              </div>

                              <div class="col-12">
                                  架電先
                                  <div class="input-group mb-3">
                                      <select
                                        :disabled="tabControl.call_record"
                                        class="form-select"
                                        name="tel_type"
                                        style="max-width: 100px"
                                        v-model="item.tel_type"
                                        @change="checkRecommendTelType(item)"
                                      >
                                        <option v-for="contact in contact_form" :key="contact.value + 'contact'" :value="contact.value">
                                          {{ contact.text }}
                                        </option>
                                      </select>
                                      <input
                                        :disabled="disableInput(item)"
                                        type="text"
                                        class="form-control"
                                        name="tel"
                                        v-model="item.tel"
                                      />
                                  </div>
                              </div>
                              <div class="col-12">
                                  結果
                                  <select
                                    :disabled="tabControl.call_record"
                                    class="form-select"
                                    v-model="item.tel_result"
                                    name="tel_result"
                                  >
                                      <option value=""></option>
                                      <option v-for="telResult in telResultArr"
                                              :key="telResult.value" :value="telResult.key">
                                          {{ telResult.value }}
                                      </option>
                                  </select>
                                  <span class="text-danger"></span>
                              </div>
                              <div class="col-12">
                                  メモ
                                  <textarea
                                    :disabled="tabControl.call_record"
                                    name="tel_memo"
                                    cols="30" rows="3"
                                    v-model="item.tel_memo"
                                    class="form-control"
                                  >
                                  </textarea>
                                  <span class="text-danger"></span>
                              </div>
                          </div>
                          <div v-if="!tabControl.call_record" class="row mt-2 mb-1">
                            <div class="d-flex justify-content-end call-recording-main-save">
                                <button type="button"
                                        class="btn btn-primary btn-sm"
                                        @click="submitDialogForm(item.id)"
                                >保存</button>
                            </div>
                        </div>
                      </div>
                  </template>
              </div>
          </div>
      <Dialog :isShow="isDialogLine" persistent :maxWidth="600" v-if="isDialogLine">
        <v-card>
          <v-container>
            <div class="row">
              <div class="col-12 d-flex">
                LINE ID
                <v-autocomplete
                    class="ml-3 col-7 container-autocomplete"
                    outlined
                    :disabled="tabControl.message"
                    v-model="lineUserAsync"
                    :items="itemsLineUser"
                    :search-input.sync="searchPatientLine"
                    hide-no-data
                    hide-details
                    no-filter
                    :loading="isLoading"
                    placeholder="ここに入力して検索"
                />
                <button v-if="!tabControl.message" style="height: 38px; margin-top: -5px;" class="ml-3 btn btn-info" @click="syncLineUser">
                  紐付け
                </button>
              </div>
            </div>
            <div class="col-12">
              <div class="mt-2"
                  v-for="(item, index) in listContactLineOld"
                  :key="item.value + index + 'patient_line'"
              >
                <input
                    :disabled="tabControl.message"
                    class="form-check-input mt-3"
                    v-model="lineUserIdChoose"
                    :value="item.value"
                    type="radio"
                    :data-text="item.label"
                    :id="item.value"
                />
                <label
                    class="mr-8 ml-3 form-check-label"
                    :for="item.value"
                >
                  {{ item.label }}
                </label>
                <button @click="deleteContactLine(item.value)" type="button" class="btn btn-danger">紐付け解除</button>
              </div>
            </div>
            <v-card-actions class=" d-flex align-content-center justify-content-between mr-2 ml-2">
              <button
                  type="button"
                  class="btn btn-secondary"
                  @click="isDialogLine = false"
              >
                キャンセル
              </button>
              <button
                  type="button"
                  class="btn btn-primary"
                  @click=choosePatientLine()
              >
                確定
              </button>
            </v-card-actions>
          </v-container>
        </v-card>
      </Dialog>
      <Dialog :maxWidth="600" :isShow="isShowDialogSubmitForm" v-if="isShowDialogSubmitForm">
        <v-card>
          <v-container>
            <v-card-title>
              確認
            </v-card-title>
            <div class="m-3">
              架電記録 変更処理を実行します。よろしいですか？
            </div>
            <v-card-actions class="
              d-flex
              align-content-center
              justify-content-between
              mr-2
              ml-2
            ">
              <button type="button" class="btn btn-secondary" @click="isShowDialogSubmitForm = false">
                キャンセル
              </button>
              <button type="button" class="btn btn-primary" @click="submitForm()">
                OK
              </button>
            </v-card-actions>
          </v-container>
        </v-card>
      </Dialog>
      <Dialog :maxWidth="600" :isShow="isShowDialogDeleteForm" v-if="isShowDialogDeleteForm">
        <v-card>
          <v-container>
            <v-card-title>
              確認
            </v-card-title>
            <div class="m-3">
              架電記録 削除処理を実行します。よろしいですか？
            </div>
            <v-card-actions class="
              d-flex
              align-content-center
              justify-content-between
              mr-2
              ml-2
            ">
              <button type="button" class="btn btn-secondary" @click="isShowDialogDeleteForm = false">
                キャンセル
              </button>
              <button type="button" class="btn btn-primary" @click="deleteFormCallRecord()">
                OK
              </button>
            </v-card-actions>
          </v-container>
        </v-card>
      </Dialog>
      <Dialog :maxWidth="600" :isShow="isDialogBtnMail" v-if="isDialogBtnMail">
        <v-card>
          <v-container>
            <v-card-title>
              確認
            </v-card-title>
            <div class="m-3">
              送信します。よろしいですか？
            </div>
            <v-card-actions class="
              d-flex
              align-content-center
              justify-content-between
              mr-2
              ml-2
            ">
              <button type="button" class="btn btn-secondary" @click="isDialogBtnMail = false">
                キャンセル
              </button>
              <button type="button" class="btn btn-primary" @click="submitBtnMail(), isDialogBtnMail = false">
                OK
              </button>
            </v-card-actions>
          </v-container>
        </v-card>
      </Dialog>
      <Dialog :maxWidth="600" :isShow="isDialogBtnMailDraft" v-if="isDialogBtnMailDraft">
        <v-card>
          <v-container>
            <v-card-title>
              確認
            </v-card-title>
            <div class="m-3">
              下書き保存します。よろしいですか？
            </div>
            <v-card-actions class="
              d-flex
              align-content-center
              justify-content-between
              mr-2
              ml-2
            ">
              <button type="button" class="btn btn-secondary" @click="isDialogBtnMailDraft = false">
                キャンセル
              </button>
              <button type="button" class="btn btn-primary" @click="submitBtnMail('draft'), isDialogBtnMailDraft = false">
                OK
              </button>
            </v-card-actions>
          </v-container>
        </v-card>
      </Dialog>
      <Dialog :maxWidth="600" :isShow="isDialogBtnSMS" v-if="isDialogBtnSMS">
        <v-card>
          <v-container>
            <v-card-title>
              確認
            </v-card-title>
            <div class="m-3">
              送信します。よろしいですか？
            </div>
            <v-card-actions class="
              d-flex
              align-content-center
              justify-content-between
              mr-2
              ml-2
            ">
              <button type="button" class="btn btn-secondary" @click="isDialogBtnSMS = false">
                キャンセル
              </button>
              <button type="button" class="btn btn-primary" @click="submitBtnSMS(), isDialogBtnSMS = false">
                OK
              </button>
            </v-card-actions>
          </v-container>
        </v-card>
      </Dialog>
      <Dialog :maxWidth="600" :isShow="isDialogBtnSMSDraft" v-if="isDialogBtnSMSDraft">
        <v-card>
          <v-container>
            <v-card-title>
              確認
            </v-card-title>
            <div class="m-3">
              下書き保存します。よろしいですか？
            </div>
            <v-card-actions class="
              d-flex
              align-content-center
              justify-content-between
              mr-2
              ml-2
            ">
              <button type="button" class="btn btn-secondary" @click="isDialogBtnSMSDraft = false">
                キャンセル
              </button>
              <button type="button" class="btn btn-primary" @click="submitBtnSMS('draft'), isDialogBtnSMSDraft = false">
                OK
              </button>
            </v-card-actions>
          </v-container>
        </v-card>
      </Dialog>
      <Dialog :maxWidth="600" :isShow="isDialogBtnLine" v-if="isDialogBtnLine">
        <v-card>
          <v-container>
            <v-card-title>
              確認
            </v-card-title>
            <div class="m-3">
              送信します。よろしいですか？
            </div>
            <v-card-actions class="
              d-flex
              align-content-center
              justify-content-between
              mr-2
              ml-2
            ">
              <button type="button" class="btn btn-secondary" @click="isDialogBtnLine = false">
                キャンセル
              </button>
              <button type="button" class="btn btn-primary" @click="submitSendLineMsg(), isDialogBtnLine = false">
                OK
              </button>
            </v-card-actions>
          </v-container>
        </v-card>
      </Dialog>
      <Dialog :maxWidth="600" :isShow="isDialogBtnLineDraft" v-if="isDialogBtnLineDraft">
        <v-card>
          <v-container>
            <v-card-title>
              確認
            </v-card-title>
            <div class="m-3">
              下書き保存します。よろしいですか？
            </div>
            <v-card-actions class="
              d-flex
              align-content-center
              justify-content-between
              mr-2
              ml-2
            ">
              <button type="button" class="btn btn-secondary" @click="isDialogBtnLineDraft = false">
                キャンセル
              </button>
              <button type="button" class="btn btn-primary" @click="submitSendLineMsg('draft'), isDialogBtnLineDraft = false">
                OK
              </button>
            </v-card-actions>
          </v-container>
        </v-card>
      </Dialog>
      <Dialog :maxWidth="600" :isShow="isShowDialogDeleteMemo" v-if="isShowDialogDeleteMemo">
        <v-card>
          <v-container>
            <v-card-title>
              確認
            </v-card-title>
            <div class="m-3">
              削除します。よろしいですか？
            </div>
            <v-card-actions class="
              d-flex
              align-content-center
              justify-content-between
              mr-2
              ml-2
            ">
              <button type="button" class="btn btn-secondary" @click="isShowDialogDeleteMemo = false">
                キャンセル
              </button>
              <button type="button" class="btn btn-primary" @click="submitDeleteMemo(), isShowDialogDeleteMemo = false">
                OK
              </button>
            </v-card-actions>
          </v-container>
        </v-card>
      </Dialog>

      <Dialog :maxWidth="600" :isShow="isShowDialogGenLink" v-if="isShowDialogGenLink">
        <v-card>
          <v-container>
            <v-card-title>
              確認
            </v-card-title>
            <div class="m-3">
              URLを生成します。よろしいですか？
            </div>
            <v-card-actions class="
              d-flex
              align-content-center
              justify-content-between
              mr-2
              ml-2
            ">
              <button type="button" class="btn btn-secondary" @click="isShowDialogGenLink = false">
                キャンセル
              </button>
              <button type="button" class="btn btn-primary" @click="generateLink(genLinkType), isShowDialogGenLink = false">
                OK
              </button>
            </v-card-actions>
          </v-container>
        </v-card>
      </Dialog>

    </div>
</template>
<style lang="scss">
    .dashboard-manager-support {
      display: flex;
      flex-direction: column;
      height: 100%;

      .contentForm {
        flex: 1 1 0;
      }

      #myTabContent {
        overflow-y: auto;
        overflow-x: hidden;
        flex: 1 1 0;
      }
    }

    .status {
      .ok {
        background: #dcfbdc;
      }
      .schedule {
        background: #ffffd9;
      }
      .error {
        background: #ffd9d9 !important;
      }
      .draft {
      background: #ebebeb;
      }
    }
</style>
<script>
import { mapMutations, mapGetters } from "vuex";
import DashboardService from "@/services/api/DashboardRepository";
import moment from "moment";
import mixins from "@/mixins";
import Repository from "@/services/api/Repository";
import {ADMIN_REPOSITORY, INTERNAL_SERVER_ERROR, SUCCESS, URL_CLIENT, BAD_REQUEST} from "@/constants";
import DashboardRepository from "../../services/api/DashboardRepository";
import { VueEditor } from "vue2-editor";
import {BTN_SAVE_DRAFT, CODE_HEALTH_GUIDANCE_DIV, CODE_SMS_HANA_ERROR, vue2EditorCustomize, TOAST_TIME_OUT, CODE_BLAST_ENGINE_ERROR} from "@/common/constants";
import Dialog from "@/components/common/Dialog";
import _ from "lodash";
import L3 from "@/services/api/L3";

export default {
    name: "ManagerSupport",
    components: {
        VueEditor,
        Dialog
    },
    props: {
        patient: null,
        showTabs: [String],
        tabControl: [Object],
    },
    data() {
        return {
            mail_other: "other",
            message_log_id: null,
            isShowDialogGenLink: false,
            isShowDialogDeleteMemo: false,
            isDialogBtnLine: false,
            isDialogBtnLineDraft: false,
            isDialogBtnSMS: false,
            isDialogBtnSMSDraft: false,
            isDialogBtnMailDraft: false,
            isDialogBtnMail: false,
            searchPatientLine: null,
            isLoading: false,
            isDialogLine: false,
            lineUserIdChoose: null,
            lineUserId: null,
            clearDataFormActive: "email",
            genLinkType: "",
            vue2EditorCustomize: vue2EditorCustomize,
            memoForm: {
                support_memo: ""
            },
            memoMsgFormErr: "",
	        timeZoneTokyo: new Date().toLocaleString("en-US", {timeZone: "Asia/Tokyo"}),
            emailForm: {
                subject: null,
                contact_template: null,
                form_template: null,
                to: null,
                content: "",
                received_email_other: "",
                received_id: null,
                segment_id: null,
                schedule: moment(this.timeZoneTokyo).format("YYYY-MM-DD HH:mm:ss"),
                type: "now" // now|schedule
            },
	        emailMsgFormErr: {
                to: null,
		        subject: null,
		        content: null,
                received_email_other: null,
		        schedule: null,
            },
            lineMsgForm: {
                contact_template: null,
                form_template: null,
                content: "",
                received_id: null,
                segment_id: null,
                schedule: moment(this.timeZoneTokyo).format("YYYY-MM-DD HH:mm:ss"),
                status: "now" // now|schedule
            },
            lineMsgFormErr: {
	            lineUserName: null,
                content: null,
                schedule: null
            },
            emailFormOrg: {
                subject: null,
                contact_template: null,
                form_template: null,
                to: null,
                content: "",
                received_id: null,
                segment_id: null,
                schedule: moment(this.timeZoneTokyo).format("YYYY-MM-DD HH:mm:ss"),
                type: "now" // now|schedule
            },
	        smsMsgFormErr: {
		        to: null,
		        content: null,
                received_email_other: null,
		        schedule: null,
	        },
            dataEdit: {
                scheduled_first_support_datetime: null,
            },
            segmentData: [],
            segmentsTelRecordList: [],
            dataTelRecord: {
                id: null,
                tel_datetime: null,
                tel_type: "01",
                tel: null,
                tel_result: null,
                tel_memo: null,
                health_support_flow_segment_id: null
            },
            dataTelRecordOrg: {
                id: null,
                tel_datetime: null,
                tel_type: null,
                tel: null,
                tel_result: null,
                tel_memo: null,
                health_support_flow_segment_id: null
            },
            call_recordings: [
                {
                    id: null,
                    tel_datetime: null,
                    tel_type: null,
                    tel: null,
                    tel_result: null,
                    tel_memo: null,
                },
            ],
            telResultArr: [
                {
                    key: 1,
                    value: "留守電"
                },
                {
                    key: 2,
                    value: "こと付け"
                },
                {
                    key: 3,
                    value: "その他"
                }
            ],
            telTypeArr: [
                {
                    key: 1,
                    value: "携帯"
                },
                {
                    key: 2,
                    value: "自宅"
                },
                {
                    key: 3,
                    value: "職場"
                }
            ],
            urlClientInputForm: null,
            contactListTemplate: null,
            formListTemplate: null,
            mailLogList: null,
            mailLogListOld: null,
            listContactLine: [{
                value: 0,
                label: ""
            }],
            listContactLineOld: [{
                value: 0,
                label: "",
                patient_id: ""
            }],
            lineUserAsync: null,
            lineUserName: null,
            isUserSyncLine: true,
            listLogMessageLine: [],
            contactForm: [],
            disable_input_tel: false,
            contact_form: [],
            isShowDialogSubmitForm: false,
            submitFormId : null,
            isShowDialogDeleteForm: false,
            deleteFormIndex: null,
            deleteFormId: null,
            codeSMSHanaError: [],
            codeBlastEngineError: [],
	        tabManagerSupport: "email",
	        templateName: null,
	        dateTimeNow: moment(new Date().toLocaleString("en-US", {timeZone: "Asia/Tokyo"})).format("YYYY-MM-DD HH:mm:ss"),
	        countMemoFormSupport: 0,
            currentTokenLinks: [],
            otherMailTimeout: null,
        };
    },
    async created() {
        this.showLoading();
	    await this.getListContactTemplate()
	    await this.getListFormTemplate()
        await this.getSegmentDetail(this.segment.id)
        await this.getMessageLogList(this.segment.id, "email")
        await this.getListContactLineOld()
        await this.getClientUrl()
        this.contactForm = await this.getCodeDefine("00000200")
        var object = _.map(this.contactForm, (text, value) => ({ value, text }));
        this.contact_form = _.sortBy(object, "value");
        await this.getListLogMessageLine()
        this.emailForm.to = this.patient?.patient_mail_01
        this.codeSMSHanaError = await this.getCodeDefine(CODE_SMS_HANA_ERROR);
        this.codeBlastEngineError = await this.getCodeDefine(CODE_BLAST_ENGINE_ERROR);
        // Set memo default null
        this.$set(this.memoForm, "support_memo", "");
        // Set current token link array from draft email form content
        let drafts = this.mailLogListOld.filter(e => {
            return e.type === "email" && e.status === "draft" && e.sender_id === this.user.id
        })
        if (drafts.length) {
            let draft = drafts[0];
            // Set email other if save draft type="other"
            let emailFormToDraft = null;
            if(draft.received_email && this.patient) {
                if(
                    draft.received_email == this.patient.patient_mail_01
                    || draft.received_email == this.patient.patient_tel_01
                    || draft.received_email == this.patient.patient_tel_02
                    || draft.received_email == this.patient.patient_tel_03
                    || draft.received_email == this.patient.patient_tel_04
                ) {
                    emailFormToDraft = draft.received_email
                } else {
                    emailFormToDraft = "other"
                }
            }
            this.$set(this.emailForm, "to", emailFormToDraft);
            // Get token links in draft content
            const regex = /^http(s)?:\/\/[^\s$.?#]+(\/[\w-.\/?%&=]*)+client\-request\?token\=+[a-zA-Z0-9]*/gm;
            // Set current token link array
            this.$set(this, "currentTokenLinks", (draft.content || "").match(regex) || []);
        }
        this.hideLoading();
    },
    watch: {
        searchPatientLine (val) {
            if(val == null || val == "" || (val && val.trim().length == 0)) {
                this.listContactLine = []
                return;
            }
            if(val && this.listContactLine.find(el => el.label === val)) return;
            if (this.isLoading) return
            this.isLoading = true
            DashboardService.getContactLine(val).then(response => {
                this.isLoading = false;
	            if(response.errorCode === INTERNAL_SERVER_ERROR) {
		            this.$toast.error(`[${response?.error.messageID}] ${response?.error.message}`, TOAST_TIME_OUT);
		            return;
	            }
                if (response && response.data && response.data.data) {
                    const {
                        data: {
                            data
                        }
                    } = response
                    this.listContactLine = data?.map(contactIt => {
                        return {
                            patient_id: contactIt.patient_id,
                            value: contactIt.line_user_id,
                            label: contactIt?.line_user_name + " - " + contactIt?.messages + (contactIt?.patient_id ? " ー　連携済み" : "")
                        }
                    })
                } else {
                    this.notifyError("LINEユーザー情報取得処理のエラーが発生しました。")
                }
            }).catch(error => {
                this.isLoading = false
                this.notifyError(`LINEユーザー情報取得処理のエラーが発生しました。内容：${error}`)
            })

        },
        segment(data) {
            if (data) {
                this.getClientUrl()
            }
            this.getSegmentDetail(this.segment.id)
            this.getMessageLogList(this.segment.id, this.tabManagerSupport)
            this.getListContactLineOld()
            this.getListLogMessageLine()
            this. dataTelRecord= {
                id: null,
                tel_datetime: null,
                tel_type: "01",
                tel: null,
                tel_result: null,
                tel_memo: null,
                health_support_flow_segment_id: null
            }
            this.disable_input_tel = false
        },
    },
    mixins: [
        mixins
    ],
    computed: {
        ...mapGetters({
            "segment": "dashboard/getSegment",
	        "user": "common/getUser"
        }),
        options() {
            return {
                format: "YYYY-MM-DD HH:mm:ss",
                useCurrent: false,
                showClear: true,
                showClose: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle",
                },
                locale: moment.locale("ja"),
            };
        },
        option_scheduled_first_support_datetime() {
            return {
                format: "YYYY-MM-DD HH:mm",
                useCurrent: false,
                showClear: true,
                showClose: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle",
                },
                locale: moment.locale("ja"),
            };
        },
        optionDate() {
            return {
                format: "YYYY-MM-DD",
                useCurrent: false,
                showClear: true,
                showClose: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle",
                },
                locale: moment.locale("ja"),
            };
        },
        option_scheduled_send_message_datetime() {
            return {
                format: "YYYY-MM-DD HH:mm",
                useCurrent: false,
                showClear: true,
                showClose: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle",
                },
                locale: moment.locale("ja"),
	            minDate: moment(this.timeZoneTokyo).format("YYYY-MM-DD HH:mm")
            };
        },
        itemsLineUser() {
            return this.listContactLine.map(contact => {
                return {
                    text: contact.label,
                    value: contact.value,
                }
            })
        },
        isValidReceiverForEmail() {
            if (!this.emailForm.to) {
                return false;
            }
            if (this.emailForm.to === this.mail_other && !this.validateEmail(this.emailForm.received_email_other || "")) {
                return false;
            }
            return true;
        },
        isValidReceiverForSMS() {
            return (this.emailForm.received_email_other || "").length;
        },
        isValidReceiverForLine() {
            return this.lineUserName;
        },
    },

    methods: {
        ...mapMutations({
            setIsLoading: "common/setIsLoading"
        }),
        validateEmail(email) {
            //const regexEmail = /^(?!.*[_.+\/?-]{2})[a-zA-Z0-9]([a-zA-Z0-9_.+\/?-])*[^\s　`!@#$%^&*()=\[\]{};':"\\|,.<>~]@[^\s　`!@#$%^&*()=\[\]{};':"\\|,.<>~]([a-z0-9_.+-]+)[.]([a-z]+)(.[a-z]+([+])?)?$/;
            const regexEmail = /^[A-Za-z0-9!-/:-@[-´{-~]*$/;
            return email.match(regexEmail);
        },
	    numbersOnly(evt) {
	        evt = (evt) ? evt : window.event;
	        const charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 || charCode === 38 || charCode === 40) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
	    countMemo(e) {
		    const maxLength = 5000;
		    const quill = this.$refs.editor.quill
		    const len = quill.getLength()
		    if (len > maxLength) {
			    quill.deleteText(maxLength, len - 1);
		    }
		    this.countMemoFormSupport = quill.getLength() - 1
	    },
        typeSendMessage(e, type) {
            let setDateTimeSchedule = new Date().toLocaleString("en-US", {timeZone: "Asia/Tokyo"});
            let drafts = this.mailLogListOld.filter(e => {
                return e.type === type && e.status === "draft" && e.sender_id === this.user.id
            })
            if (drafts.length) {
                let draft = drafts[0];
                if (draft.time_send_message_schedule) {
                    if (type && type == "email") {
                        this.$set(this.emailForm, "schedule", (draft.time_send_message_schedule < setDateTimeSchedule)
                            ? moment(setDateTimeSchedule).format("YYYY-MM-DD HH:mm")
                            : draft.time_send_message_schedule);
                    }
                    if (type && type == "line") {
                        this.$set(this.lineMsgForm, "schedule", (draft.time_send_message_schedule < setDateTimeSchedule)
                            ? moment(setDateTimeSchedule).format("YYYY-MM-DD HH:mm")
                            : draft.time_send_message_schedule);
                    }
                } else {
                    if (type && type == "sms") {
                        this.$set(this.emailForm, "schedule", moment(setDateTimeSchedule).add(5, "minutes").format("YYYY-MM-DD HH:mm:ss"));
                    } else {
                        if (type && type == "email") {
                            this.$set(this.emailForm, "schedule", moment(setDateTimeSchedule).format("YYYY-MM-DD HH:mm:ss"));
                        }
                        if (type && type == "line") {
                            this.$set(this.lineMsgForm, "schedule", moment(setDateTimeSchedule).format("YYYY-MM-DD HH:mm:ss"));
                        }
                    }
                }
            } else if (e.target && e.target.value == "schedule") {
                if (type && type == "sms") {
                    this.$set(this.emailForm, "schedule", moment(setDateTimeSchedule).add(5, "minutes").format("YYYY-MM-DD HH:mm:ss"));
                } else {
                    this.$set(this.lineMsgForm, "schedule", moment(setDateTimeSchedule).format("YYYY-MM-DD HH:mm:ss"));
                }
            }
        },
        async submitDeleteMemo() {
            try {
                await DashboardService.submitDeleteMemo(this.message_log_id).then(res => {
                    this.message_log_id = null;
	                if(res.errorCode === INTERNAL_SERVER_ERROR) {
		                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		                return;
	                }
                    if (res.status === 200) {
                        this.notifySuccess("削除が完了しました。");
                        this.getMessageLogList(this.segment.id)
                    } else {
                        this.notifyError("削除の処理にエラーが発生しました。");
                    }
                })
            } catch (e) {
                this.message_log_id = null;
                this.notifyError("削除の処理にエラーが発生しました。");
                return this.setErrors(e);
            }
        },


        /**
         * SMSフォームの送信先コンボックス変更時
         * @param {} e 
         */
        async changeSMSFormTo(e) {
            let value = e.target.value;
            if(value != "other"){
                // 直接指定 以外の場合
                this.$set(this.emailForm, "received_email_other", value);
                // Show loading
                this.showLoading();
                // Get new token link
                let data = {
                    "type": "sms",
                    "segment_id": this.segment.id,
                    "target": value,
                };
                let url = await this.getTokenLink(data);
                // Replace new token link in email form content
                let newEmailFormContent = this.emailForm.content || "";
                this.currentTokenLinks.forEach(function(value) {
                    newEmailFormContent = newEmailFormContent.replaceAll(value, url);
                });
                this.resetEmailFormContent(newEmailFormContent);
                // Reset current token link array
                this.$set(this, "currentTokenLinks", [url]);
                // Hide loading
                this.hideLoading();
            } else {
                // 直接指定 の場合
                this.$set(this.emailForm, "received_email_other", null);
            }
        },
        getReceivedEmail(received_email) {
            if(!this.patient || !received_email) return
            switch(received_email) {
            case this.patient.patient_tel_01:
                return "(携帯)" + received_email ;
            case this.patient.patient_tel_02:
                return "(自宅)" + received_email ;
            case this.patient.patient_tel_03:
                return "(職場)" + received_email ;
            case this.patient.patient_tel_04:
                return "(その他)" + received_email ;
            default:
                return "(未指定)" + received_email
            }
        },
        getMessError(error_code, error_message, type) {
            if(type == "sms") {
                return error_message ? "[" + error_code + "]" + error_message : "[" + error_code + "]" + this.codeSMSHanaError[error_code]
            }
            if(type == "email") {
                return error_code && this.codeBlastEngineError[error_code] ? "[" + error_code + "]" + this.codeBlastEngineError[error_code] : "[" + error_code + "]" + error_message
            }
            if(type == "line") {
                return "[" + error_code + "]" + error_message
            }
            return;
        },
        async submitSendMemo() {
            try {
                if (!this.memoForm.support_memo.trim()) {
                    this.memoMsgFormErr = "メモを入力してください。";
                    return;
                }
                await DashboardService.segmentSendMemo(this.memoForm, this.segment.id).then(res => {
	                if(res.errorCode === INTERNAL_SERVER_ERROR) {
		                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		                return;
	                }
                    if (res.status === 200) {
                        this.notifySuccess("メモを保存しました。");
                        this.getMessageLogList(this.segment.id)
	                    this.memoForm.support_memo = "";
                        this.memoMsgFormErr = "";
                    }
                })
            } catch (e) {
                this.notifyError("メモの保存が失敗しました。");
                return this.setErrors(e);
            }
        },
        clearMemo() {
            this.memoForm.support_memo = null
        },
        clearDataForm(action){
            // Reset current token link array
            this.$set(this, "currentTokenLinks", []);
            // let type = "email";
            let type = action == "mail" ? "email" : action;
	        let tzTokyo = new Date().toLocaleString("en-US", {timeZone: "Asia/Tokyo"})
            if(action == "mail" && this.clearDataFormActive != "mail"){
                this.clearDataFormActive = action;
	            this.tabManagerSupport = "email";
                this.emailMsgFormErr = {
                    to: null,
	                subject: null,
	                content: null,
                    received_email_other: null,
	                schedule: null,
                };
                this.emailForm = {
                    subject: null,
                    contact_template: null,
                    form_template: null,
                    to: this.patient?.patient_mail_01,
                    content: "",
                    received_id: null,
                    segment_id: null,
                    schedule: moment(tzTokyo).format("YYYY-MM-DD HH:mm:ss"),
                    type: "now",
                    received_email_other: null
                };
                this.lineMsgForm = {
                    contact_template: null,
                    form_template: null,
                    content: "",
                    received_id: null,
                    segment_id: null,
                    schedule: moment(tzTokyo).format("YYYY-MM-DD HH:mm:ss"),
                    status: "now" // now|schedule
                };
            }
            if(action == "sms" && this.clearDataFormActive != "sms"){
                this.clearDataFormActive = action;
                this.tabManagerSupport = "sms";
                this.smsMsgFormErr = {
                    to: null,
                    content: null,
                    received_email_other: null,
	                schedule: null,
                };
                this.emailForm = {
                    subject: null,
                    contact_template: null,
                    form_template: null,
                    to: this.patient?.patient_tel_01,
                    content: "",
                    received_id: null,
                    segment_id: null,
                    schedule: moment(tzTokyo).add(5, "minutes").format("YYYY-MM-DD HH:mm:ss"),
                    type: "now",
                    received_email_other: null
                };
                this.lineMsgForm = {
                    contact_template: null,
                    form_template: null,
                    content: "",
                    received_id: null,
                    segment_id: null,
                    schedule: moment(tzTokyo).format("YYYY-MM-DD HH:mm:ss"),
                    status: "now" // now|schedule
                };
            }
            if(action == "line" && this.clearDataFormActive != "line"){
                this.clearDataFormActive = action;
                this.tabManagerSupport = "line";
                this.lineMsgFormErr = {
                    lineUserName: null,
                    content: null,
                };
                this.emailForm = {
                    subject: null,
                    contact_template: null,
                    form_template: null,
                    to: this.patient?.patient_mail_01,
                    content: "",
                    received_id: null,
                    segment_id: null,
                    schedule: moment(tzTokyo).format("YYYY-MM-DD HH:mm:ss"),
                    type: "now"
                };
	            this.lineMsgForm = {
		            contact_template: null,
		            form_template: null,
		            content: "",
		            received_id: null,
		            segment_id: null,
		            schedule: moment(tzTokyo).format("YYYY-MM-DD HH:mm:ss"),
		            status: "now" // now|schedule
	            };
	            this.lineUserName = null;
            }

            let drafts = this.mailLogListOld.filter(e => {
                return e.type === type && e.status === "draft" && e.sender_id === this.user.id
            })

            // this.mailLogList = drafts;

            if (drafts.length) {
                let draft = drafts[0];
                // Get token links in draft content
                const regex = /^http(s)?:\/\/[^\s$.?#]+(\/[\w-.\/?%&=]*)+client\-request\?token\=+[a-zA-Z0-9]*/gm;
                // Set current token link array
                this.$set(this, "currentTokenLinks", (draft.content || "").match(regex) || []);
                let emailFormTo = null;
                let typeDraft = null;
                if(draft.received_email && this.patient) {
                    if(
	                    draft.received_email == this.patient.patient_mail_01
	                    || draft.received_email == this.patient.patient_tel_01
	                    || draft.received_email == this.patient.patient_tel_02
	                    || draft.received_email == this.patient.patient_tel_03
	                    || draft.received_email == this.patient.patient_tel_04
                    ) {
                        emailFormTo = draft.received_email
                    } else {
                        emailFormTo = "other"
                    }

                }

	            let line_user = this.listContactLineOld.find(i => i.value === draft.line_user_id);
                // If type = "email" => set type = "mail" (To compare with type in db)
                let tempType = type === "email" ? "mail" : type;
	            let templateNameFilter = this.contactListTemplate.findIndex(e => {
		            return e.type === tempType && e.title === draft.title
	            })

                if (draft.status && draft.status == "draft") {
                    if (draft.time_send_message_schedule) {
                        typeDraft = "schedule"
                    } else {
                        typeDraft = "now"
                    }
                }

                this.emailForm = {
                    id: draft.id,
                    subject: draft.title,
                    contact_template: templateNameFilter,
                    form_template: null,
                    to: emailFormTo,
                    content: draft.content,
                    received_id: draft.received_id,
                    segment_id: draft.segment_id,
	                schedule: (draft.time_send_message_schedule < tzTokyo) ? moment(tzTokyo).format("YYYY-MM-DD HH:mm") : draft.time_send_message_schedule,
	                type: typeDraft, // now|schedule
                    received_email_other: draft.received_email,
	                received_email: draft.received_email
                }
                if (type == "sms") {
                    if (draft.time_send_message_schedule < moment(tzTokyo).add(5, "minutes").format("YYYY-MM-DD HH:mm")) {
                        this.emailForm.schedule = moment(tzTokyo).add(5, "minutes").format("YYYY-MM-DD HH:mm");
                    }
                }
	            if (type == "line") {
		            this.lineMsgForm = {
                        id: draft.id,
			            subject: draft.title,
			            contact_template: templateNameFilter,
			            form_template: draft.form_template || null,
			            content: draft.content,
			            received_id: draft.received_id,
			            segment_id: draft.segment_id,
			            schedule: draft.schedule || moment(tzTokyo).format("YYYY-MM-DD HH:mm:ss"),
			            status: typeDraft, // now|schedule
		            };
		            this.lineUserName = line_user?.label;
		            this.lineUserId = line_user?.value;
                    this.lineUserIdChoose = line_user?.value;
	            }
            }else{
                this.emailForm = {
                    subject: null,
                    contact_template: null,
                    form_template: null,
                    to: action == "mail" ? this.patient?.patient_mail_01 : null,
                    content: "",
                    received_id: null,
                    segment_id: null,
                    schedule: moment(new Date().toLocaleString("en-US", {timeZone: "Asia/Tokyo"})).format("YYYY-MM-DD HH:mm"),
                    type: "now", // now|schedule
                    received_email_other: null,
	                received_email: null
                };
	            this.lineMsgForm = {
		            contact_template: null,
		            form_template: null,
		            content: "",
		            received_id: null,
		            segment_id: null,
		            schedule: moment(new Date().toLocaleString("en-US", {timeZone: "Asia/Tokyo"})).format("YYYY-MM-DD HH:mm"),
		            status: "now" // now|schedule
	            };
	            this.lineUserName = null;
            }
        },
        valueAndText(segment, code) {
            // return segment.support_flow_segment_evals
            return (segment.support_flow_segment_evals).filter(e => {
                return e?.health_support_eval_items?.eval_item_code == code
            })
        },
        checkStatus(status, time_send_message_schedule, item){
            if (item.type == "feedback") return "問い合わせ 受信"
            if (item.type != "memo" && item.status != "draft" && item.status != "schedule" && !item.title && !item.content) return "対象者回答 受信"
            switch (status) {
            case "draft":
                return "下書き";
            case "ok":
                return "送信OK";
            case "schedule":
                return "送信予約 " + moment(time_send_message_schedule).format("YYYY-MM-DD HH:mm");
            case "error":
                return "送信NG";
            case "pending":
                return "送信済み";
            case "memo":
                return "メモ";
            default:
                return "";
            }
        },
        getClassLog(item) {
            if(item.type != "memo" && item.type != "feedback" && item.status != "draft" && item.status != "schedule" && !item.title && !item.content) return "log_l4"
            if(item.status == "error") return "log_error"
            if(item.type == "feedback") return "log_feedback"
            if(item.type == "memo") return "log_memo"
            if(item.status == "pending" || item.status == "schedule") return "log_schedule"
            if(item.status == "ok") return "log_ok"
            return "log"
        },
        typeOfMessageLog(type, send_type = "send") {
            let txt = "";
            switch (type) {
            case "email":
                txt = "メール";
                break;
            case "line":
                txt = "LINE";
                break;
            case "sms":
                txt = "ショートメール";
                break;
            }

            if (send_type == "send") {
                return txt + "送信";
            }
            return txt + "受信";
        },
        revertStringWithLink(string) {
            const URLMatcher = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/igm
            // return (string.replace("\n\r", "<br>")) //-- .replace(URLMatcher, match => `<a href="${match}">${match}</a>`)

            if (typeof string === "string") {
                return (string.replace(/\n/g, "<br>")).replace(URLMatcher, match => `<a href="${match}">${match}</a>`);
            }
        },
        async getSegmentDetail(segment_id) {
            try {
                await Repository.get(`${ADMIN_REPOSITORY}/health-support-flow-segment/detail/${segment_id}`).then(res => {
	                if(res.errorCode === INTERNAL_SERVER_ERROR) {
		                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		                return;
	                }
                    this.$root.$emit("checkSupportProgressStatus", { status: res.data.data.support_progress_status });
                    this.segmentData = res.data.data;
                    this.memoForm.support_memo = res.data.data.support_memo
                    this.telRecordData();
                })
            } catch (e) {
                return this.setErrors(e);
            }
        },
        async getMessageLogList(segment_id, type = null) {
            this.$set(this, "emailForm", JSON.parse(JSON.stringify(this.emailFormOrg)));
            try {
                await DashboardService.getMessageLogList(segment_id).then(res => {
	                if(res.errorCode === INTERNAL_SERVER_ERROR) {
		                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		                return;
	                }
                    this.mailLogList = res.data.data;
                    this.mailLogListOld = res.data.data;

                    let drafts = this.mailLogList.filter(e => {
                        return e.type === type && e.status === "draft" && e.sender_id === this.user.id
                    })

	                // this.lineMsgForm = {}
	                this.lineUserName = null;
                    // Initialize email schedule for the first time
                    if (!drafts.length) {
                        this.emailForm.schedule = moment(new Date().toLocaleString("en-US", {timeZone: "Asia/Tokyo"})).format("YYYY-MM-DD HH:mm");
                    }
                    if (drafts.length) {
                        let draft = drafts[0];
	                    let emailFormToDraft = null;
                        let typeDraft = null;
	                    if(draft.received_email && this.patient) {
		                    if(
                                draft.received_email == this.patient.patient_mail_01
                                || draft.received_email == this.patient.patient_tel_01
                                || draft.received_email == this.patient.patient_tel_02
                                || draft.received_email == this.patient.patient_tel_03
                                || draft.received_email == this.patient.patient_tel_04
                            ) {
			                    emailFormToDraft = draft.received_email
		                    } else {
			                    emailFormToDraft = "other"
		                    }
	                    }
                        // If type = "email" => set type = "mail" (To compare with type in db)
                        let tempType = type === "email" ? "mail" : type;
	                    let templateNameFilter = this.contactListTemplate.findIndex(e => {
		                    return e.type === tempType && e.title === draft.title
	                    })
                        if (draft.status && draft.status == "draft") {
                            if (draft.time_send_message_schedule) {
                                typeDraft = "schedule"
                            } else {
                                typeDraft = "now"
                            }
                        }
                        this.$set(this, "emailForm", {
                            id: draft.id,
                            subject: draft.title,
                            contact_template: templateNameFilter,
                            form_template: null,
                            to: emailFormToDraft,
                            received_email: draft.received_email,
                            received_email_other: draft.received_email,
                            content: draft.content,
                            received_id: draft.received_id,
                            segment_id: draft.segment_id,
                            schedule: (this.emailForm.type && this.emailForm.type == "now")
                                ? ((draft.time_send_message_schedule < this.dateTimeNow)
                                    ? moment(this.timeZoneTokyo).format("YYYY-MM-DD HH:mm")
                                    : draft.time_send_message_schedule)
                                : null,
                            type: typeDraft // now|schedule
                        });
	                    if (type === "line") {
                            this.$set(this, "lineMsgForm", {
                                id: draft.id,
                                contact_template: templateNameFilter,
                                form_template: draft.form_template || null,
                                content: draft.content,
                                received_id: draft.received_id,
                                segment_id: draft.segment_id,
                                schedule: (this.lineMsgForm.status && this.lineMsgForm.status == "now")
                                    ? moment(this.timeZoneTokyo).format("YYYY-MM-DD HH:mm")
                                    : draft.time_send_message_schedule,
                                line_user_id: draft.line_user_id,
                                status: typeDraft // now|schedule
                            });
		                    let line_user = this.listContactLineOld.find(i => i.value === draft.line_user_id);
                            this.$set(this, "lineUserName", line_user?.label);
	                    }
                    }
                })
            } catch (e) {
                return this.setErrors(e);
            }
        },
        async getListContactTemplate() {
            try {
                await DashboardService.listContactTemplate().then(res => {
	                if(res.errorCode === INTERNAL_SERVER_ERROR) {
		                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		                return;
	                }
                    this.contactListTemplate = res.data;
                })
            } catch (e) {
                return this.setErrors(e);
            }
        },
        async getListFormTemplate() {
            try {
                await DashboardService.listFormTemplate().then(res => {
	                if(res.errorCode === INTERNAL_SERVER_ERROR) {
		                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		                return;
	                }
                    this.formListTemplate = res.data;
                })
            } catch (e) {
                return this.setErrors(e);
            }
        },
	    changeContactList(e) {
		    let selectedTemplateValue = e.target.value;
            if (selectedTemplateValue !== "") {
                let value = this.contactListTemplate[e.target.value];
                this.templateName = value?.name;
                this.emailForm.subject = value?.title;
                // Add receiver to API which get token link
                let target = this.emailForm.to === this.mail_other ? this.emailForm.received_email_other : this.emailForm.to;
                let data = {
                    "type": value?.type === "mail" ? "email" : value?.type,
                    "segment_id": this.segment.id,
                    "target": target,
                };
                DashboardService.getGenerateLink(data).then(res => {
                    let url = location.origin + res.data;
                    if(res.errorCode === INTERNAL_SERVER_ERROR) {
                        this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
                        return;
                    }
                    // Reset current token link array
                    this.$set(this, "currentTokenLinks", [url]);
                    this.$set(this.emailForm, "content", value.content.replace("${patient_name}", this.patient.patient_name).replace("${url}", url));
                })
            }
	    },
	    changeContactTemplate({target}) {
            let selectedTemplateValue = target.value;
            if (selectedTemplateValue !== "") {
                this.$set(this.lineMsgForm, "content", "");
                const { name, content, type } = this.contactListTemplate[target.value];
                let data = {
                    "type": type,
                    "segment_id": this.segment.id,
                    "target": this.lineUserName.split(" - ")[0] || this.lineUserName,
                };
                DashboardService.getGenerateLink(data).then(res => {
                    let url = location.origin + res.data;
                    if(res.errorCode === INTERNAL_SERVER_ERROR) {
                        this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
                        return;
                    }
                    // Reset current token link array
                    this.$set(this, "currentTokenLinks", [url]);
                    this.lineMsgForm = {
                        ...this.lineMsgForm,
                        content: content.replace("${patient_name}", this.patient.patient_name).replace("${url}", url),
                    }
                })
            }
        },

        /**
         * 
         */
        showGenerateLinkConfirmDialog(type = "email") {
            this.genLinkType = type;
            this.isShowDialogGenLink = true;
        },

        /**
         * 
         */
        async generateLink(type = "email") {
            try {
                // Add receiver to API which get token link
                let target = "";
                if (type === "line") {
                    target = this.lineUserName.split(" - ")[0] || this.lineUserName;
                }
                if (type !== "line" && this.emailForm.to !== this.mail_other) {
                    target = this.emailForm.to;
                }
                if (type !== "line" && this.emailForm.to === this.mail_other) {
                    target = this.emailForm.received_email_other;
                }
                let data = {
                    "type": type,
                    "segment_id": this.segment.id,
                    "target": target,
                };
                await DashboardService.getGenerateLink(data).then(res => {
                    let url = location.origin + res.data;
	                if(res.errorCode === INTERNAL_SERVER_ERROR) {
		                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		                return;
	                }
                    // Add token link to current token link array
                    this.currentTokenLinks.push(url);
                    if (type === "line") {
	                    if ((this.lineMsgForm.content.length + url.length) > 2000) {
		                    this.$toast.error("全角2000文字以上を入力したので、URL生成できません。", TOAST_TIME_OUT);
		                    return;
	                    }
                        this.lineMsgForm.content += "\n" + url;
                    } else {
	                    if ((this.emailForm.content.length + url.length) > 2000) {
		                    this.$toast.error("全角2000文字以上を入力したので、URL生成できません。", TOAST_TIME_OUT);
		                    return;
	                    }
                        this.emailForm.content += "\n" + url;
                    }
                })
            } catch (e) {
                return this.setErrors(e);
            } finally {
                this.genLinkType = "";
            }
        },
	    
        checkValidate(fields, isSMS = false, isLine = false) {
            const errorMessages = {};
		    const checkTimeZoneTokyo = new Date().toLocaleString("en-US", {timeZone: "Asia/Tokyo"})
            const dateTimeNow = moment(checkTimeZoneTokyo).format("YYYY-MM-DD HH:mm");

            Object.entries(fields).forEach(([field, label]) => {
	            if (isLine) {
		            if (field === "lineUserName") {
			            if (!this.lineUserName || this.lineUserName.trim() === "") {
				            errorMessages[field] = `${label}を選択してください。`;
			            } else {
				            errorMessages[field] = null;
			            }
		            }
		            if (field === "content") {
			            if (!this.lineMsgForm.content || this.lineMsgForm.content.trim() === "") {
				            errorMessages[field] = `${label}を選択してください。`;
			            } else {
				            errorMessages[field] = null;
			            }
		            }
		            if (field === "schedule") {
			            if (!this.lineMsgForm.schedule || this.lineMsgForm.schedule.trim() === "") {
				            errorMessages[field] = `${label}を選択してください。`;
			            } else if (this.lineMsgForm[field] && this.lineMsgForm[field] < dateTimeNow) {
				            errorMessages[field] = "時間は現在より未来にしてください";
			            } else {
				            errorMessages[field] = null;
			            }
		            }
                } else if(field == "received_email_other") {
                    if (this.emailForm.to && this.emailForm.to == "other") {
                        if(!this.emailForm[field] || (this.emailForm[field] && this.emailForm[field].trim() === "")) {
                            errorMessages[field] = `${label}を入力してください。`;
                        } else if(!this.validateEmail(this.emailForm[field]) && !isSMS) {
                            errorMessages[field] = "メールアドレスはメールアドレスを入力してください。";
                        } else {
                            errorMessages[field] = null;
                        }
                    } else {
                        errorMessages[field] = null;
                    }
                } else {
                    if (!this.emailForm[field] || this.emailForm[field].trim() === "") {
                        errorMessages[field] = `${label}を入力してください。`;
                    } else if (field === "schedule") {
                        if (isSMS) {
                            let smsAddMinutes = moment(checkTimeZoneTokyo).add(5, "minutes").format("YYYY-MM-DD HH:mm");
                            if (this.emailForm[field] && this.emailForm[field] < smsAddMinutes) {
                                errorMessages[field] = "送信予約の時間指定は"+moment(checkTimeZoneTokyo).add(5, "minutes").format("HH:mm")+"以降にしてください。";
                            }
                        } else if (this.emailForm[field] && this.emailForm[field] < dateTimeNow) {
                            errorMessages[field] = "時間は現在より未来にしてください";
                        }
                    } else {
                        errorMessages[field] = null;
                    }
                }
            });

            return errorMessages;
        },
        async submitBtnMail(type = "") {
            this.emailForm.schedule = this.emailForm.type == "schedule" ? this.emailForm.schedule : null;
            if (type === "draft") {
                this.emailForm.type = "draft";
            }

            const fieldsMail = {
                to: "送信先",
                subject: "タイトル",
                content: "本文入力",
                received_email_other: "メールアドレス"
            };
            if (this.emailForm.type == "schedule") {
                fieldsMail.schedule = "時間";
            }
            this.emailMsgFormErr = this.checkValidate(fieldsMail);

            var checkValidate = true;
            for (const [key, value] of Object.entries(this.emailMsgFormErr)) {
                if (value != null) {
                    checkValidate = false;
                    break;
                }
            }

            try {
                if (checkValidate) {
                    this.showLoading();
                    this.emailForm.segment_id = this.segment.id;
                    this.emailForm.received_id = this.patient.id;
                    this.emailForm.target = this.emailForm.to !== this.mail_other ? this.emailForm.to : this.emailForm.received_email_other;
                    await DashboardService.segmentSendMail(this.emailForm).then(res => {
                        this.emailMsgFormErr = {};
                        this.hideLoading();
	                    if(res.errorCode === INTERNAL_SERVER_ERROR || res.errorCode === BAD_REQUEST) {
                            this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		                    return;
	                    }
                        if (res.status === 200) {
                            if (type === "draft") {
                                this.notifySuccess("メールの下書きが完了しました。");
                            } else {
                                this.notifySuccess("メールの送信が完了しました。");
                            }
                            this.$set(this, "emailForm", JSON.parse(JSON.stringify(this.emailFormOrg)));
                            this.$set(this.emailForm, "contact_template", this.templateName);
                            this.getMessageLogList(this.segment.id, "email")
                        }
                    })
                }
            } catch (e) {
                this.notifyError("Error send mail");
                return this.setErrors(e);
            }
        },
        async submitBtnSMS(type = "") {
            this.emailForm.schedule = this.emailForm.type == "schedule" ? this.emailForm.schedule : null;
            if (type === "draft") {
                this.emailForm.type = "draft";
            }

            const fieldsSms = {
                to: "送信先",
                content: "本文入力",
                received_email_other: "送信先"
            };
            if (this.emailForm.type == "schedule") {
                fieldsSms.schedule = "時間";
            }
            this.smsMsgFormErr = this.checkValidate(fieldsSms, true);

            var checkValidate = true;
            for (const [key, value] of Object.entries(this.smsMsgFormErr)) {
                if (value != null) {
                    checkValidate = false;
                    break;
                }
            }

            try {
                if (checkValidate) {
                    this.showLoading();
                    this.emailForm.segment_id = this.segment.id;
                    this.emailForm.received_id = this.patient.id;
                    this.emailForm.target = this.emailForm.received_email_other;
                    DashboardService.segmentSendSMS(this.emailForm).then(res => {
                        this.smsMsgFormErr = {};
                        this.hideLoading();
	                    if(res.errorCode === INTERNAL_SERVER_ERROR) {
		                    this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		                    return;
	                    }
                        if (res.status === 200) {
                            if (type === "draft") {
                                this.notifySuccess("SMSの下書きが完了しました。");
                            } else {
                                this.notifySuccess("SMSの送信が完了しました。");
                            }
                            this.$set(this, "emailForm", JSON.parse(JSON.stringify(this.emailFormOrg)));
                        }
                        this.getMessageLogList(this.segment.id, "sms")
                    })
                }
            } catch (e) {
                this.notifyError("SMS送信のエラーが発生しました。");
                return this.setErrors(e);
            }
        },
        async submitSendLineMsg(type = "") {
            this.lineMsgForm.schedule = this.lineMsgForm.status == "schedule" ? this.lineMsgForm.schedule : null;
	        if (type === "draft") {
		        this.lineMsgForm.status = "draft";
	        }

            const fieldsLine = {
                lineUserName: "LINE ID",
                content: "本文入力"
            };
	        if (this.lineMsgForm.status == "schedule") {
		        fieldsLine.schedule = "時間";
	        }
            this.lineMsgFormErr = this.checkValidate(fieldsLine, false, true);

            var checkValidate = true;
            for (const [key, value] of Object.entries(this.lineMsgFormErr)) {
                if (value != null) {
                    checkValidate = false;
                    break;
                }
            }

            if (checkValidate) {
                this.showLoading()
	            let templateNameFilter = this.contactListTemplate.filter((e, i) => {
		            return e.type === "line" && i === this.lineMsgForm.contact_template
	            })
                this.lineMsgForm = {
                    ...this.lineMsgForm,
                    segment_id: this.segment.id,
                    received_id: this.patient.id,
                    line_user_id: this.lineUserIdChoose ? this.lineUserIdChoose : this.lineUserId
                };
                this.lineMsgForm.subject = templateNameFilter[0]?.title || null;
                this.lineMsgForm.target = this.lineUserName.split(" - ")[0] || this.lineUserName;
                DashboardService.segmentSendLineMsg(this.lineMsgForm).then(res => {
                    this.lineMsgFormErr = {}
                    this.hideLoading();
	                if(res.errorCode === INTERNAL_SERVER_ERROR) {
		                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		                return;
	                }
                    if (res.status === 200) {
                        if (type === "draft") {
                            this.notifySuccess("LINEメッセージを正常に下書きしました。");
                        } else {
                            this.notifySuccess("LINEメッセージを正常に送信しました。");
                            this.$set(this, "lineMsgForm", {
                                contact_template: null,
                                form_template: null,
                                content: "",
                                received_id: null,
                                segment_id: null,
                                schedule: moment(this.timeZoneTokyo).format("YYYY-MM-DD HH:mm:ss"),
                                status: "now",
                                line_user_id: null,
                            });
	                        this.lineUserName = null;
                        }
                        this.getMessageLogList(this.segment.id, "line")
                    }
                }).catch (e => {
                    this.hideLoading()
                    this.notifyError(`LINEメッセージ送信のエラーが発生しました。内容： ${e.message}`);
                    return this.setErrors(e);
                })
            }
        },
        async setSegmentState(segment_id) {
            try {
                await Repository.get(`${ADMIN_REPOSITORY}/health-support-flow-segment/detail/${segment_id}`).then(res => {
	                if(res.errorCode === INTERNAL_SERVER_ERROR) {
		                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		                return;
	                }
                    this.setSegment(res.data.data);
                })
            } catch (e) {
                return this.setErrors(e);
            }
        },
        async telRecordData() {
            this.segmentsTelRecordList = JSON.parse(JSON.stringify(this.segmentData.tels_records));
        },
        addTelRecord() {
            this.segmentsTelRecordList.push(this.dataTelRecord);
            this.dataTelRecord = JSON.parse(JSON.stringify(this.dataTelRecordOrg));
            DashboardService.storeSegmentTels(this.segment.id, this.segmentsTelRecordList[this.segmentsTelRecordList.length - 1]).then(res => {
	            if(res.errorCode === INTERNAL_SERVER_ERROR) {
		            this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		            return;
	            }
                if (res.data.data) {
                    this.notifySuccess("架電記録の追加が完了しました。")
                    this.getSegmentDetail(this.segment.id);
                } else {
                    this.notifyError("err")
                }
            })
        },
        deleteFormCallRecordDig(index, id) {
            this.isShowDialogDeleteForm = true
            this.deleteFormIndex = index
            this.deleteFormId = id
        },
        deleteFormCallRecord() {
            this.segmentsTelRecordList.splice(this.deleteFormIndex, 1);
            let data = {
                "delete_segment_tel_ids": [this.deleteFormId],
                "tels": [],
            }
            DashboardService.updateSegmentTels(this.segment.id, data).then(res => {
	            if(res.errorCode === INTERNAL_SERVER_ERROR) {
		            this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		            return;
	            }
                if (res.data.data) {
                    this.isShowDialogDeleteForm = false
                    this.deleteFormIndex = null
                    this.deleteFormId = null
                    this.notifySuccess("架電記録の削除が完了しました。")
                } else {
                    this.isShowDialogDeleteForm = false
                    this.notifyError("err")
                }
            })
        },
        async submitDialogForm(id) {
            this.isShowDialogSubmitForm = true
            this.submitFormId = id
        },
        async submitForm() {
            let _this = this
            let data = {
                "delete_segment_tel_ids": [],
                "tels": this.segmentsTelRecordList.filter(x => x.id == _this.submitFormId),
            }
            await DashboardService.updateSegmentTels(this.segment.id, data).then(res => {
	            if(res.errorCode === INTERNAL_SERVER_ERROR) {
		            this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		            return;
	            }
                if (res.data.data) {
                    this.notifySuccess("架電記録の更新が完了しました。")
                    _this.submitFormId = null
                    _this.isShowDialogSubmitForm = false
                } else {
                    this.notifyError("err")
                    _this.isShowDialogSubmitForm = false
                }
            })
        },
        getClientUrl() {
            try {
                this.urlClientInputForm = "https://localhost:8080/client-request?token=sdsdsds"
            } catch (e) {
                this.notifyError(e.message)
            }
        },
        async getListContactLineOld() {
            await DashboardService.getContactLineOld(this.patient.id).then(response => {
	            if(response.errorCode === INTERNAL_SERVER_ERROR) {
		            this.$toast.error(`[${response?.error.messageID}] ${response?.error.message}`, TOAST_TIME_OUT);
		            return;
	            }
                if (response && response.data && response.data.data) {
                    const {
                        data: {
                            data
                        }
                    } = response
                    this.listContactLineOld = data?.map(contactIt => {
                        return {
                            patient_id: contactIt.patient_id,
                            value: contactIt.line_user_id,
                            label: contactIt?.line_user_name + " - " + contactIt?.messages
                        }
                    })
                } else {
                    this.notifyError("LINEユーザー情報取得処理のエラーが発生しました。")
                }
            }).catch(error => {
                this.notifyError(`LINEユーザー情報取得処理のエラーが発生しました。内容：${error}`)
            })
        },
        async deleteContactLine(line_user_id) {
            await DashboardService.deleteContactLine(line_user_id).then(response => {
	            if(response.errorCode === INTERNAL_SERVER_ERROR) {
		            this.$toast.error(`[${response?.error.messageID}] ${response?.error.message}`, TOAST_TIME_OUT);
		            return;
	            }
                if (response) {
                    this.getListContactLineOld()
                } else {
                    this.notifyError("対象者のLINEアカウントへの紐付けが失敗しました。")
                }
            }).catch(error => {
                this.hideLoading()
                this.notifyError(`LINEユーザー情報取得処理のエラーが発生しました。内容：${error}`)
            })
        },
        async choosePatientLine() {
            if(this.lineUserIdChoose) {
                this.lineUserId = this.lineUserIdChoose
                let idInput = "#" + this.lineUserIdChoose
                if (this.lineMsgForm.content && $(idInput).data("text") && this.lineUserName !== $(idInput).data("text")) {
                    // Show loading
                    this.showLoading();
                    // Get new token link
                    let data = {
                        "type": "line",
                        "segment_id": this.segment.id,
                        "target": $(idInput).data("text"),
                    };
                    let url = await this.getTokenLink(data);
                    // Replace new token link in line form content
                    let newLineFormContent = this.lineMsgForm.content || "";
                    this.currentTokenLinks.forEach(function(value) {
                        newLineFormContent = newLineFormContent.replaceAll(value, url);
                    });
                    this.$set(this.lineMsgForm, "content", newLineFormContent);
                    // Reset current token link array
                    this.$set(this, "currentTokenLinks", [url]);
                    // Hide loading
                    this.hideLoading();
                }
                this.lineUserName = $(idInput).data("text")
            }
            this.isDialogLine = false
        },
        async syncLineUser() {
            if(!this.lineUserAsync) return
            this.showLoading()
            const syncLineUserData = {
                line_user_id: this.lineUserAsync,
                patient_id: this.patient.id
            }
            await DashboardService.syncLineContact(syncLineUserData).then(response => {
                this.hideLoading();
	            if(response.errorCode === INTERNAL_SERVER_ERROR) {
		            this.$toast.error(`[${response?.error.messageID}] ${response?.error.message}`, TOAST_TIME_OUT);
		            return;
	            }
                if (response && response.data && response.data.data) {
                    this.isUserSyncLine = true;
                    this.notifySuccess("対象者のLINEアカウントに紐付けました。")
                    this.lineUserAsync = null
                    this.getListContactLineOld()
                } else {
                    this.notifyError("対象者のLINEアカウントへの紐付けが失敗しました。")
                }
            }).catch(error => {
                this.hideLoading()
                this.notifyError(`対象者のLINEアカウントへの紐付けが失敗しました。原因は：${error}`)
            })
        },
        async getListLogMessageLine() {
            const requestLogLine = {
                patientId: this.patient.id,
                segmentId: this.segment.id
            }
            await DashboardRepository.getLogMessageLineByPatientId(requestLogLine)
                .then(response => {
                    const {
                        data
                    } = response

                    if (data && data.data && data.data.length) {
                        this.listLogMessageLine = data.data
                    } else {
                        this.isUserSyncLine = false
                    }
                })
                .catch(error => {
                    this.notifyError(`メッセージ一覧取得のエラーが発生しました。内容： ${error}`)
                })
        },
        checkRecommendTelType(item) {
            let that = this;
            if (item.tel_type != "10" && item.tel_type != "20" && item.tel_type != "30" && item.tel_type != "99") {
                item.tel = null;
                that.disable_input_tel = false
            }
            if (item.tel_type == "") {
                that.disable_input_tel = true
            }
            if (item.tel_type == "10") {
                item.tel = this.patient.patient_tel_01;
                that.disable_input_tel = true
            }
            if (item.tel_type == "20") {
                item.tel = this.patient.patient_tel_02;
                that.disable_input_tel = true
            }
            if (item.tel_type == "30") {
                item.tel = this.patient.patient_tel_03;
                that.disable_input_tel = true
            }
            if (item.tel_type == "99") {
                item.tel = this.patient.patient_tel_04;
                that.disable_input_tel = true
            }
        },
        disableInput(item) {
            if (this.tabControl.call_record) return true

            if (item.tel_type == null) {
                return true;
            }

            if (item.tel_type != "10" && item.tel_type != "20" && item.tel_type != "30" && item.tel_type != "99") {
                return false
            }
            return true;
        },
        async onChangeReceiver(event) {
            let drafts = this.mailLogListOld.filter(e => {
                return e.type === "email" && e.status === "draft" && e.sender_id === this.user.id
            })
            if (drafts.length) {
                let draft = drafts[0];
                let emailFormToDraft = null;
                if(draft.received_email && this.patient) {
                    if(draft.received_email == this.patient.patient_mail_01) {
                        emailFormToDraft = draft.received_email
                    } else {
                        emailFormToDraft = "other"
                    }
                }
                if (emailFormToDraft != "other") {
                    this.$set(this.emailForm, "received_email_other", "");
                }
            }
            this.$set(this.emailForm, "to", event.target.value);
            // Replace new token link in email form content
            if (this.isValidReceiverForEmail && this.emailForm.content) {
                // Show loading
                this.showLoading();
                // Get new token link
                let target = this.emailForm.to === this.mail_other ? this.emailForm.received_email_other : this.emailForm.to;
                let data = {
                    "type": "email",
                    "segment_id": this.segment.id,
                    "target": target,
                };
                let url = await this.getTokenLink(data);
                // Replace new token link in email form content
                let newEmailFormContent = this.emailForm.content || "";
                this.currentTokenLinks.forEach(function(value) {
                    newEmailFormContent = newEmailFormContent.replaceAll(value, url);
                });
                this.resetEmailFormContent(newEmailFormContent);
                // Reset current token link array
                this.$set(this, "currentTokenLinks", [url]);
                // Hide loading
                this.hideLoading();
            }
        },
        onChangeReceivedOtherEmail(event) {
            this.$set(this.emailForm, "received_email_other", event.target.value);
            // Remove received other email error
            this.$set(this.emailMsgFormErr, "received_email_other", null);
            // Check valid received other email
            if (event.target.value && !this.validateEmail(event.target.value)) {
                this.$set(this.emailMsgFormErr, "received_email_other", "メールアドレスはメールアドレスを入力してください。");
                return;
            }
            // Replace new token link in email form content
            if (this.otherMailTimeout) {
                clearTimeout(this.otherMailTimeout);
            }
            let app = this;
            if (app.emailForm.content) {
                this.otherMailTimeout = setTimeout(async function () {
                    // Show loading
                    app.showLoading();
                    // Get new token link
                    let data = {
                        "type": "email",
                        "segment_id": app.segment.id,
                        "target": event.target.value,
                    };
                    let url = await app.getTokenLink(data);
                    // Replace new token link in email form content
                    let newEmailFormContent = app.emailForm.content || "";
                    app.currentTokenLinks.forEach(function(value) {
                        newEmailFormContent = newEmailFormContent.replaceAll(value, url);
                    });
                    app.resetEmailFormContent(newEmailFormContent);
                    // Reset current token link array
                    app.$set(app, "currentTokenLinks", [url]);
                    // Hide loading
                    app.hideLoading();
                }, 1000);
            }
        },
        resetEmailFormContent(content = "") {
            this.$set(this.emailForm, "content", content);
        },
        async getTokenLink(data) {
            data.target = data.target.split(" - ")[0] || this.lineUserName;
            let res = await DashboardService.getGenerateLink(data);
            if(res.errorCode === INTERNAL_SERVER_ERROR) {
                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
                return null;
            }
            return location.origin + res.data
        },
        onChangeSMS(event) {
            // set received_email_other as sms number
            this.$set(this.emailForm, "received_email_other", event.target.value);
            // Replace new token link in email form content
            if (this.otherMailTimeout) {
                clearTimeout(this.otherMailTimeout);
            }
            let app = this;
            if (app.emailForm.content) {
                this.otherMailTimeout = setTimeout(async function () {
                    // Show loading
                    app.showLoading();
                    // Get new token link
                    let data = {
                        "type": "sms",
                        "segment_id": app.segment.id,
                        "target": event.target.value,
                    };
                    let url = await app.getTokenLink(data);
                    // Replace new token link in email form content
                    let newEmailFormContent = app.emailForm.content || "";
                    app.currentTokenLinks.forEach(function(value) {
                        newEmailFormContent = newEmailFormContent.replaceAll(value, url);
                    });
                    app.resetEmailFormContent(newEmailFormContent);
                    // Reset current token link array
                    app.$set(app, "currentTokenLinks", [url]);
                    // Hide loading
                    app.hideLoading();
                }, 1000);
            }
        },
    }
}
</script>

<style lang="sass">
    @import "../../styles/managerSupport"
    .table-stripped
        & tbody
            & tr
                &:nth-child(6)
                    & td
                        border: none
                        background-color: $white !important

                    & td
                        border: thin solid rgba(0, 0, 0, .12)

                        &:nth-child(odd)
                            background-color: $backgroundDisabledPagination

        .container-info-datetime
            & .v-sheet
                display: flex
                justify-content: center
                align-items: center
    .table-info-l4
        padding: 0
        & tr
            & td:first-child
                width: 55%
            & td
                & span
                    padding: 0
.container-autocomplete.v-text-field--outlined
    .v-input__control
        .v-input__slot
            min-height: 10px
.container-autocomplete.v-text-field--enclosed
    .v-input__append-inner
        margin-top: 2px
.log_schedule
    background-color: #fff2cc
.log_ok
    background-color: #e2f0d9
    color: #385724
.log_error
    color: #ff0000
    background-color: #f2dff6
.log_feedback
    background-color: #fbe5d6
    color: #843c0b
.log_l4
    background-color: #fbe5d6
    color: #843c0b
.log_memo
    background-color: #DEEAF6
    color: #1E4E79
.box-log
    word-wrap: break-word
.cursor-auto
    cursor: auto
/* Chrome, Safari, Edge, Opera */
.phone-number::-webkit-outer-spin-button,
.phone-number::-webkit-inner-spin-button
    -webkit-appearance: none
    margin: 0
/* Firefox */
.phone-number[type=number]
    -moz-appearance: textfield
</style>
