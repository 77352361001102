<template>
    <v-card>
        <v-container>
            <v-card-title>確定解除</v-card-title>
            <div class="m-3">確定解除処理を実行します。よろしいですか？</div>
            <v-card-actions class="d-flex align-content-center justify-content-between mr-2 ml-2">
                <button type="button" class="btn btn-secondary" @click="$emit('closeDialog')">{{ $t('common.close') }}</button>
                <button type="button" class="btn btn-primary" @click="$emit('deleteSegment')">{{ $t('common.confirm') }}</button>
            </v-card-actions>
        </v-container>
    </v-card>
</template>

<script>
export default {
    name: "DialogDelete",
    props: {},
};
</script>

<style scoped>

</style>